import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport'
import { metaOptions } from '@/api/auth.ts'
import { GraderPublicClient } from '@/protogen/grader_service/grader_service.client.ts'
import {
  AllTestResults,
  AssessmentResult,
  ContestResult,
  CourseStatistics,
  CourseStudentsGrades,
  FileGrade,
  FileGrades,
  Filters,
  PersonalCourseGrades,
  SectionStatistics,
  SubmitGradableFileEntry,
  SubmitTestAnswersEntry,
  TaskResult,
  TestDetails,
  TestResult,
} from '@/protogen/grader_service/grader_service.ts'
import { apiRequestWrapper } from '@/helpers/apiWrapper'

const client = new GraderPublicClient(
  new GrpcWebFetchTransport({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
  }),
)

export default () => ({
  submitTestAnswers: async (
    submissionId: number,
    submissionFileId: Uint8Array,
    testShortConfigFileId: Uint8Array,
    emulatedUserId?: number,
    emulatedSubmissionTime?: bigint,
  ): Promise<void> => {
    const request: SubmitTestAnswersEntry = {
      submissionId,
      submissionFileId,
      testShortConfigFileId,
      emulatedUserId: emulatedUserId || 0,
      emulatedSubmissionTime: emulatedSubmissionTime || 0n,
    }
    return apiRequestWrapper(async () => {
      await client.submitTestAnswers(request, metaOptions)
    })
  },

  getAllTestResults: async (submissionId: number): Promise<AllTestResults> => {
    const request = { submissionId }
    return apiRequestWrapper(async () => {
      const response = await client.getAllTestResults(request, metaOptions)
      return response.response
    })
  },

  getTestResult: async (submissionId: number, userId: number): Promise<TestResult> => {
    const request = { submissionId, userId }
    return apiRequestWrapper(async () => {
      const response = await client.getTestResult(request, metaOptions)
      return response.response
    })
  },
  getTestDetails: async (submissionId: number): Promise<TestDetails> => {
    const request = { submissionId }
    return apiRequestWrapper(async () => {
      const response = await client.getTestDetails(request, metaOptions)
      return response.response
    })
  },

  overrideAnswerResult: async (
    submissionId: number,
    userId: number,
    prevResFileId: Uint8Array,
    resEditsFileId: Uint8Array,
  ): Promise<void> => {
    const request = { submissionId, userId, prevResFileId, resEditsFileId }
    return apiRequestWrapper(async () => {
      await client.overrideAnswerResult(request, metaOptions)
    })
  },

  regradeTest: async (submissionId: number): Promise<void> => {
    const request = { submissionId }
    return apiRequestWrapper(async () => {
      await client.regradeTest(request, metaOptions)
    })
  },

  submitGradableFile: async (
    assessmentId: number,
    submissionFileId: Uint8Array,
    isText: boolean,
    emulatedUserId?: number,
    emulatedSubmissionTime?: bigint,
  ): Promise<void> => {
    const request: SubmitGradableFileEntry = {
      assessmentId,
      submissionFileId,
      isText,
      emulatedUserId: emulatedUserId || 0,
      emulatedSubmissionTime: emulatedSubmissionTime || 0n,
    }
    return apiRequestWrapper(async () => {
      await client.submitGradableFile(request, metaOptions)
    })
  },

  getAllFileGrades: async (courseId: number, filters: Filters): Promise<FileGrades> => {
    const request = { courseId, filters }
    return apiRequestWrapper(async () => {
      const response = await client.getAllFileGrades(request, metaOptions)
      return response.response
    })
  },

  gradeFile: async (
    assessmentId: number,
    userId: number,
    gradedFileId: Uint8Array,
    score: number,
    comments: string,
  ): Promise<void> => {
    const request = { assessmentId, userId, gradedFileId, score, comments }
    return apiRequestWrapper(async () => {
      await client.gradeFile(request, metaOptions)
    })
  },

  getFileGrade: async (assessmentId: number, userId: number): Promise<FileGrade> => {
    const request = { assessmentId, userId }
    return apiRequestWrapper(async () => {
      const response = await client.getFileGrade(request, metaOptions)
      return response.response
    })
  },

  getContestResult: async (submissionId: number, userId: number): Promise<ContestResult> => {
    const request = { submissionId, userId }
    return apiRequestWrapper(async () => {
      const response = await client.getContestResult(request, metaOptions)
      return response.response
    })
  },

  getTaskResult: async (problemId: number, submissionId: number, userId: number): Promise<TaskResult> => {
    const request = { problemId, submissionId, userId }
    return apiRequestWrapper(async () => {
      const response = await client.getTaskResult(request, metaOptions)
      return response.response
    })
  },

  getAssessmentResult: async (assessmentId: number, userId: number): Promise<AssessmentResult> => {
    const request = { assessmentId, userId }
    return apiRequestWrapper(async () => {
      const response = await client.getAssessmentResult(request, metaOptions)
      return response.response
    })
  },

  getSectionStats: async (sectionId: number, userId: number): Promise<SectionStatistics> => {
    const request = { sectionId, userId }
    return apiRequestWrapper(async () => {
      const response = await client.getSectionStatistics(request, metaOptions)
      return response.response
    })
  },

  getCourseStats: async (courseId: number, userId: number): Promise<CourseStatistics | void> => {
    const request = { courseId, userId }
    return apiRequestWrapper(async () => {
      const response = await client.getCourseStatistics(request, metaOptions)
      return response.response
    })
  },
  getCourseStudentsGrades: async (courseId: number): Promise<CourseStudentsGrades> => {
    const request = { courseId }
    return apiRequestWrapper(async () => {
      const response = await client.getCourseStudentsGrades(request, metaOptions)
      return response.response
    })
  },
  getPersonalCourseGrades: async (courseId: number, userId: number): Promise<PersonalCourseGrades | void> => {
    const request = { courseId, userId }
    return apiRequestWrapper(async () => {
      const response = await client.getPersonalCourseGrades(request, metaOptions)
      return response.response
    })
  },
})
