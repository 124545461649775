import { toZonedTime, fromZonedTime } from 'date-fns-tz'
import { useUserStore } from '@/store/userStore'

export const convertToUnixTimestamp = (date: Date): bigint => {
  return BigInt(Math.floor(date.getTime() / 1000))
}

export const convertFromUnixTimestamp = (date: BigInt | number): Date => {
  return new Date(Number(date) * 1000)
}

export const convertDate = (date: Date | bigint | number): Date =>
  typeof date === 'bigint' || typeof date === 'number' ? convertFromUnixTimestamp(date) : date

/**
 * Transfers the time from the user's selected timezone to the local timezone.
 */
export const convertToTimeZone = (date: Date): Date => {
  const userStore = useUserStore()

  return toZonedTime(fromZonedTime(date, userStore.timezone), Intl.DateTimeFormat().resolvedOptions().timeZone)
}

/**
 * Transfers the local time to the user's selected timezone.
 */
export const convertFromTimeZone = (date: Date | bigint | number): Date => {
  const userStore = useUserStore()

  const currentDate = convertDate(date)
  return toZonedTime(fromZonedTime(currentDate, Intl.DateTimeFormat().resolvedOptions().timeZone), userStore.timezone)
}
