import { defineStore } from 'pinia'

import { useOrganizationStore } from './organization'

import api from '@/api/userInfo'
import apiFileManager from '@/api/fileManager'

import { DEFAULT_TIMEZONE } from '@/data/constants'

import { ErrorCodeEnum } from '@/interfaces/error'
import { User, Users } from '@/protogen/userinfo_service/userinfo_service.ts'
import { UserOrganizationSpeciality } from '@/types/organization/organization'

import { toHex } from '@/helpers/toHex'

interface State {
  user: User | null
  userMap: Map<number, User>
  avatarUrl: string
  timezone: string
  userSpecialities: UserOrganizationSpeciality[]
  availableOrganizations: number[]
  loading: boolean
}

export const useUserStore = defineStore('user', {
  state: (): State => {
    return {
      user: null,
      userMap: new Map<number, User>(),
      avatarUrl: '',
      timezone: DEFAULT_TIMEZONE,
      userSpecialities: [],
      availableOrganizations: [],
      loading: false,
    }
  },
  actions: {
    async fetchUser() {
      try {
        this.loading = true
        this.user = await api().getUser(0, 0)

        if (this.user.pictureFileId.length) {
          const avatarResponse = await apiFileManager().getFile(toHex(this.user.pictureFileId))
          if (avatarResponse) {
            this.avatarUrl = avatarResponse.url
          }
        }

        const response = await api().getTimeZonePreference()

        if (!response) return

        if (response.timezone === 'Etc/UTC') this.timezone = DEFAULT_TIMEZONE
        else this.timezone = response.timezone
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    storeUsers(userIds: number[], users: User[]) {
      if (userIds.length !== users.length) {
        console.error('The number of userIds and users must be the same')
        return
      }

      for (let i = 0; i < userIds.length; i++) {
        this.userMap.set(userIds[i], users[i])
      }
    },
    getCachedUsers(userIds: number[]): { ids: number[]; users: User[] } {
      const cachedIds = userIds.filter((id) => this.userMap.has(id))
      const cachedUsers = cachedIds.map((id) => this.userMap.get(id)).filter((user): user is User => user !== undefined)

      return { ids: cachedIds, users: cachedUsers }
    },
    async getUser(userId: number) {
      if (this.userMap.has(userId)) {
        return this.userMap.get(userId) ?? null
      }
      try {
        const response = await api().getUser(userId, 0)

        if (!response) return null

        this.userMap.set(userId, response)

        return response
      } catch (error) {
        console.error('Cannot fetch user information:', error)
        return null
      }
    },
    async getUserIdByEmail(userEmail: string) {
      if (!userEmail) return null

      for (const [userId, userInfo] of this.userMap) {
        if (userInfo.email === userEmail) return userId
      }

      try {
        const response = await api().getUserIdByEmail(userEmail, 0)
        return response?.id || null
      } catch (error: any) {
        if (error.code == ErrorCodeEnum.NOT_FOUND) {
          console.warn('You don not have enough access rights.:', error)
          return 0 // Default id
        }
        console.error('Cannot fetch user information:', error)
        return null
      }
    },
    async fetchAndStoreUsers(userIds: number[], courseId: number): Promise<Users | null> {
      try {
        const response = await api().getUsers(userIds, courseId)
        if (!response) return null

        this.storeUsers(response.ids, response.users)

        return { ids: response.ids, users: response.users }
      } catch (error) {
        console.error('Cannot fetch user information:', error)
        return null
      }
    },
    async getUsers(userIds: number[], courseId?: number): Promise<Users | null> {
      const targetCourseId = courseId ?? 0

      const { ids: cachedIds, users: cachedUsers } = this.getCachedUsers(userIds)

      if (cachedIds.length === userIds.length) {
        return { ids: cachedIds, users: cachedUsers }
      }

      const nonCachedUserIds = userIds.filter((id) => !cachedIds.includes(id))

      const fetchedUsers = await this.fetchAndStoreUsers(nonCachedUserIds, targetCourseId)
      if (!fetchedUsers) return null

      const allIds = [...cachedIds, ...fetchedUsers.ids]
      const allUsers = [...cachedUsers, ...fetchedUsers.users]

      return { ids: allIds, users: allUsers }
    },

    async getSpecialities() {
      const organizationStore = useOrganizationStore()

      const organizations = await api().getCurrentUserMemberships()

      const specialities = await api().getOrganizationSpecialities([
        ...organizationStore.organizationsList.map((org) => org.organizationId),
      ])

      this.availableOrganizations = organizations.ids.sort((firstId, secondId) => firstId - secondId)

      this.userSpecialities = specialities.organizationIds
        .map((orgId, index) => {
          return { organizationId: orgId, specialityId: specialities.specialityIds[index] }
        })
        .sort((a, b) => a.organizationId - b.organizationId)
    },
    clearStore(): void {
      this.user = null
    },
  },
})
