import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport'
import { metaOptions } from '@/api/auth.ts'
import { FileManagerPublicClient } from '@/protogen/file_manager_service/file_manager_service.client.ts'
import CryptoJS from 'crypto-js'
import { FileInfo } from '@/protogen/file_manager_service/file_manager_service.ts'
import { showErrorNotification } from '@/helpers/errorNotification.ts'
import { AccessInfo } from '@/interfaces/file'
import { apiRequestWrapper } from '@/helpers/apiWrapper'

const client = new FileManagerPublicClient(
  new GrpcWebFetchTransport({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
  }),
)

export default () => ({
  uploadFile: async (
    file: File,
    accessInfo: AccessInfo,
    header?: { 'x-access-config': string },
  ): Promise<Uint8Array | undefined> => {
    const fileContents = await file.arrayBuffer()
    const wordArray = CryptoJS.lib.WordArray.create(fileContents)
    const md5Hash = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Hex)

    try {
      let guests = ''
      for (const guestId of accessInfo.guestIds) {
        guests += `&guest=${guestId}`
      }
      const response = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/files/upload?sum=${md5Hash}&size=${file.size}&name=${file.name}&access=${accessInfo.accessType}&course=${accessInfo.courseId}${guests}`,
        {
          method: 'POST',
          headers: {
            Authorization: `${localStorage.getItem('accessToken') || ''}`,
            ...(header ?? {}),
          },
          body: fileContents,
        },
      )

      if (response.ok || response.status === 409) {
        return new Uint8Array(await response.arrayBuffer())
      }
    } catch (error) {
      throw error
    }
  },

  downloadFile: async (sum: string, inline = false): Promise<void> => {
    const url = `${import.meta.env.VITE_API_BASE_URL}/files/download?sum=${sum}${inline ? '&inline=true' : ''}`
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `${localStorage.getItem('accessToken') || ''}`,
        },
      })

      if (response.ok) {
        const fileName = response.headers.get('Content-Disposition')?.split('filename=')
        const blob = await response.blob()
        const link = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        link.href = url
        if (inline) {
          window.open(url)
        } else if (fileName) {
          link.download = fileName[1].replace(/"/g, '')
          link.click()
        } else {
          link.download = sum
          link.click()
        }
        window.URL.revokeObjectURL(url)
      }
    } catch (error) {
      throw error
    }
  },
  getFileInfo: async (hashSum: Uint8Array): Promise<FileInfo> => {
    const request = { hashSum }
    return apiRequestWrapper(async () => {
      const response = await client.getFileInfo(request, metaOptions)
      return response.response
    })
  },
  getFile: async (sum: string, inline = false) => {
    const url = `${import.meta.env.VITE_API_BASE_URL}/files/download?sum=${sum}${inline ? '&inline=true' : ''}`

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `${localStorage.getItem('accessToken') || ''}`,
        },
      })
      if (response.ok) {
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        return { blob, url }
      } else {
        console.error(`Failed to download file. Status: ${response.status}`)
      }
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  },
  downloadFileGRPC: async (hashSum: Uint8Array): Promise<{ blob: Blob; name: string }> => {
    const request = { hashSum }
    try {
      const responseStream = client.downloadFile(request, metaOptions)

      let fileInfo: { name: string; size: number } = { name: 'file', size: 0 }
      const chunks: Uint8Array[] = []

      for await (const response of responseStream.responses) {
        if (response.data.oneofKind === 'fileInfo') {
          fileInfo = response.data.fileInfo
        } else if (response.data.oneofKind === 'chunkData') {
          chunks.push(response.data.chunkData)
        }
      }

      const blob = new Blob(chunks, { type: 'application/octet-stream' })
      return { name: fileInfo.name, blob: new Blob([blob], { type: 'application/octet-stream' }) }
    } catch (error: any) {
      showErrorNotification(error.code)
      throw error
    }
  },
})
