import { createRouter, createWebHistory } from 'vue-router'
import CourseRoutes from './modules/course/course'
import ContestsRoutes from './modules/contests/contests'

const routes = [
  {
    path: '/',
    component: () => import('@/layout/EntitiesLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/HomeView.vue'),
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('@/views/profile/Profile.vue'),
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('@/components/Settings/index.vue'),
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: () => import('@/views/calendar/Calendar.vue'),
      },
      {
        path: 'news',
        name: 'News',
        component: () => import('@/views/news/News.vue'),
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: () => import('@/views/notifications/Notifications.vue'),
      },
      {
        path: ':pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/error-page/NotFound.vue'),
      },
      {
        path: 'organization',
        name: 'Organization',
        component: () => import('@/views/admin/organization.vue'),
      },
      {
        path: 'userRequest',
        name: 'UserRequest',
        component: () => import('@/views/admin/userRequest.vue'),
      },
      {
        path: 'queue',
        name: 'Queue',
        component: () => import('@/views/queue/Queue.vue'),
      },

      {
        path: 'homework/:courseId/:id/:userId',
        name: 'Homework',
        component: () => import('@/views/homework/submission-review/BasicCheckPage.vue'),
      },
      {
        path: 'pdf',
        name: 'PdfEditor',
        component: () => import('@/views/homework/pdf-editor/PdfEditor.vue'),
      },
      ...CourseRoutes,
      ...ContestsRoutes,
    ],
  },
  {
    path: '/login',
    component: () => import('@/views/auth/SignIn.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            top: 10,
            behavior: 'smooth',
          })
        }, 700)
      })
    }

    if (to.path !== from.path) {
      return { top: 0 }
    }

    return false
  },
})

router.beforeEach((to, _from, next) => {
  const loggedIn = localStorage.getItem('accessToken')

  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    })
  } else {
    next()
  }
})

export default router
