import { AuthClient } from '@/protogen/auth_service/auth_service.client'
import {
  AccountInfoResponse,
  AuthTokens,
  GetNewSecretQRResponse,
  LoginAvailability,
  SendEmailPasscodeRequest,
} from '@/protogen/auth_service/auth_service'
import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport'
import { showErrorNotification } from '@/helpers/errorNotification.ts'
import { apiRequestWrapper } from '@/helpers/apiWrapper'
import { useAuthStore } from '@/store/auth'

const client = new AuthClient(
  new GrpcWebFetchTransport({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
  }),
)

export const refreshJWT = async (): Promise<void> => {
  const request = { refreshToken: localStorage.getItem('refreshToken') || '' }
  try {
    const response = await client.refreshJWT(request)
    localStorage.setItem('accessToken', response.response.accessToken)
    localStorage.setItem('refreshToken', response.response.refreshToken)
    metaOptions = { meta: { authorization: response.response.accessToken } }
  } catch (error) {
    const authStore = useAuthStore()
    authStore.logout()
  }
}

export let metaOptions = { meta: { authorization: localStorage.getItem('accessToken') || '' } }

export default () => ({
  getAccountInfo: async (email: string): Promise<AccountInfoResponse> => {
    const request = { email }
    try {
      const response = await client.getAccountInfo(request)
      return response.response
    } catch (error: any) {
      showErrorNotification(error.code)
      throw error
    }
  },
  sendEmailPasscode: async (email: string): Promise<void> => {
    const request = SendEmailPasscodeRequest.create({ email })
    try {
      await client.sendEmailPasscode(request)
    } catch (error: any) {
      showErrorNotification(error.code)
    }
  },
  validatePasscode: async (email: string, passcode: string, isMobileAuth: boolean): Promise<void> => {
    const request = { email, passcode, isMobileAuth }
    const response = await client.validatePasscode(request)
    localStorage.setItem('accessToken', response.response.accessToken)
    localStorage.setItem('refreshToken', response.response.refreshToken)
  },
  checkLoginAvailability: async (email: string): Promise<LoginAvailability> => {
    const request = { email }
    return apiRequestWrapper(async () => {
      const response = await client.checkLoginAvailability(request, metaOptions)
      return response.response
    })
  },
  getNewSecretQR: async (passcode: string, isMobileAuth: boolean): Promise<GetNewSecretQRResponse> => {
    const request = { passcode, isMobileAuth }
    return apiRequestWrapper(async () => {
      const response = await client.getNewSecretQR(request, metaOptions)
      return response.response
    })
  },
  authWithGoogle: async (idToken: string): Promise<AuthTokens> => {
    const request = { idToken }
    try {
      const response = await client.authWithGoogle(request)
      localStorage.setItem('accessToken', response.response.accessToken)
      localStorage.setItem('refreshToken', response.response.refreshToken)
      return response.response
    } catch (error: any) {
      showErrorNotification(error.code)
      throw error
    }
  },
  terminateSessions: async (): Promise<void> => {
    const refreshToken = localStorage.getItem('refreshToken') ?? ''
    const request = { refreshToken }
    return apiRequestWrapper(async () => {
      await client.logout(request, metaOptions)
    })
  },
})
