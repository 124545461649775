import { defineStore } from 'pinia'

import { useUserStore } from './userStore'

import api from '@/api/organizations'
import courseApi from '@/api/course'

import { ErrorCodeEnum } from '@/interfaces/error'
import { Permissions } from '@/protogen/organizations_service/organizations_service'
import {
  BlockCourse,
  OrganizationBlock,
  OrganizationSpeciality,
  OrganizationStated,
  UsedOrganization,
} from '@/types/organization/organization'

import { formatBlockInfos } from '@/helpers/formatBlockInfos'

export interface State {
  organizationsList: UsedOrganization[]
  deletedOrganizationsList: OrganizationStated[]
  activeOrganizationId: number
  loading: boolean
  logoUrl: string
}

export const useOrganizationStore = defineStore('organization', {
  state: (): State => ({
    organizationsList: [],
    deletedOrganizationsList: [],
    activeOrganizationId: 0,
    loading: true,
    logoUrl: '',
  }),
  actions: {
    async fetchOrganizations() {
      try {
        const userStore = useUserStore()
        this.loading = true

        const [orgsRsp, orgPermissionsRsp, orgsDeletedRsp] = await Promise.all([
          api().getAllOrganizationsInfo(),
          api().getAllOrganizationsPermissions(),
          this.fetchDeletedOrganizations(),
        ])

        const permissionsMap = new Map(
          orgPermissionsRsp.organizationIds.map((id, index) => [id, orgPermissionsRsp.permissions[index]]),
        )

        const [specialitiesMap, blocksMap] = await Promise.all([
          this.fetchSpecialitiesMap(orgsRsp.organizationIds),
          this.fetchBlocksMap(orgsRsp.organizationIds),
        ])

        this.organizationsList = orgsRsp.organizationIds.map((id, index) => ({
          organizationId: id,
          organizationInfo: orgsRsp.organizationInfos[index],
          permissions: permissionsMap.get(id) || ({} as Permissions),
          specialities: specialitiesMap.get(id) || null,
          blockList: blocksMap.get(id) || [],
        }))

        this.deletedOrganizationsList = orgsDeletedRsp.organizationIds.map((id, index) => ({
          organizationId: id,
          organizationInfo: orgsDeletedRsp.organizationInfos[index],
          permissions: permissionsMap.get(id) || ({} as Permissions),
          specialities: specialitiesMap.get(id) || null,
        }))

        await userStore.getSpecialities()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },

    async fetchDeletedOrganizations() {
      try {
        return await api().getDeletedOrganizationsInfo()
      } catch (error: any) {
        if (error.code === ErrorCodeEnum.PERMISSION_DENIED) {
          console.warn('Insufficient access rights: ', error)
        }
        throw error
      }
    },

    async fetchSpecialitiesMap(organizationIds: number[]) {
      const specialitiesMap = new Map<number, OrganizationSpeciality[] | null>()
      await Promise.all(
        organizationIds.map(async (id) => {
          specialitiesMap.set(id, await this.getSpecialities(id))
        }),
      )
      return specialitiesMap
    },

    async fetchBlocksMap(organizationIds: number[]) {
      const blocksMap = new Map<number, OrganizationBlock[]>()
      await Promise.all(
        organizationIds.map(async (id) => {
          const list = await this.getOrganizationBlocks(id)
          blocksMap.set(id, list)
        }),
      )
      return blocksMap
    },
    async fetchBlocks(): Promise<void> {
      try {
        const org = this.getActiveOrganization()
        if (!org) return

        const blocks = await this.getOrganizationBlocks(this.activeOrganizationId)
        const response = await courseApi().getOrganizationBlockCourses(this.activeOrganizationId)

        const responseMap = new Map<number, Map<number, Set<number>>>()

        response.blockIds.forEach((blockId: number, index: number) => {
          const courseId = response.courseIds[index]
          const specialityId = response.specialityIds[index]

          if (!responseMap.has(blockId)) {
            responseMap.set(blockId, new Map())
          }

          const courseMap = responseMap.get(blockId)!

          if (!courseMap.has(courseId)) {
            courseMap.set(courseId, new Set())
          }

          courseMap.get(courseId)!.add(specialityId)
        })

        const blockList = blocks.map((block) => {
          const activeBlock = JSON.parse(JSON.stringify(block))

          const courseMap = responseMap.get(activeBlock.blockId)

          if (courseMap === undefined) return activeBlock

          courseMap.forEach((specialitySet: Set<number>, courseId: number) => {
            let course = activeBlock.courses.find((c: BlockCourse) => c.courseId === courseId)

            if (!course) {
              course = { courseId, specialityIds: [] }
              activeBlock.courses.push(course)
            }

            course.specialityIds.push(...Array.from(specialitySet).filter((id) => !course!.specialityIds.includes(id)))
          })

          return activeBlock
        })

        org.blockList = [...blockList]
      } catch (error) {
        console.error(`Couldn't get a list of courses in the block: `, error)
      }
    },
    async ensureOrganizationsLoaded() {
      if (!this.organizationsList.length) {
        await this.fetchOrganizations()
      }

      this.organizationsList = this.organizationsList
        .filter((org) => org.organizationId !== 1)
        .sort((a, b) => a.organizationId - b.organizationId)

      if (this.organizationsList.length) {
        this.activeOrganizationId = this.organizationsList[0].organizationId
      }
    },
    hasAdministratorRights() {
      return this.organizationsList.some((org) => org.permissions.canUpdate)
    },
    async getSpecialities(orgId: number): Promise<OrganizationSpeciality[] | null> {
      const response = await api().getOrganizationSpecialities(orgId)

      if (!response.specialities) return null

      return response.specialities.map((speciality) => {
        return { ...speciality, isEditing: false } as OrganizationSpeciality
      })
    },
    async fetchSpecialities(orgId: number): Promise<void> {
      const specialitiesList = await this.getSpecialities(orgId)

      if (!specialitiesList) return

      const organizationIndex = this.organizationsList.findIndex((org) => org.organizationId === orgId)

      this.organizationsList[organizationIndex].specialities = specialitiesList
    },
    getActiveOrganization(): UsedOrganization | null {
      return (
        this.organizationsList.find((org: UsedOrganization) => org.organizationId === this.activeOrganizationId) || null
      )
    },
    async getOrganizationBlocks(organizationId?: number): Promise<OrganizationBlock[]> {
      const targetOrganizationId = organizationId ?? this.activeOrganizationId

      const response = await courseApi().getOrganizationBlocks(targetOrganizationId)

      return formatBlockInfos(response)
    },
  },
})
