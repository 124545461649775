export default [
  {
    path: 'contest-configuration/:id/:index',
    component: () => import('@/views/contest/configuration/ContestConfiguration.vue'),
    name: 'Configuration',
  },
  {
    path: 'contest/:id/:index/:slug',
    component: () => import('@/views/tasks/TaskPage.vue'),
    name: 'ContestView',
    redirect: { name: 'problems' },
    children: [
      {
        path: 'problems',
        name: 'problems',
        component: () => import('@/views/contest/tabs/ProblemsListTab.vue'),
      },
      {
        path: 'task',
        name: 'task',
        component: () => import('@/views/contest/tabs/ProblemTab.vue'),
      },
      {
        path: 'results/:solutionId',
        name: 'results',
        component: () => import('@/views/contest/tabs/ResultsTab.vue'),
      },
      {
        path: 'submissions',
        name: 'submissions',
        component: () => import('@/views/contest/tabs/HistoryTab.vue'),
      },
    ],
  },
]
