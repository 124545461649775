// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "courses_service.proto" (package "courses_service", syntax proto3)
// tslint:disable
import { ServiceType } from '@protobuf-ts/runtime-rpc'
import { WireType } from '@protobuf-ts/runtime'
import type { BinaryWriteOptions } from '@protobuf-ts/runtime'
import type { IBinaryWriter } from '@protobuf-ts/runtime'
import { UnknownFieldHandler } from '@protobuf-ts/runtime'
import type { BinaryReadOptions } from '@protobuf-ts/runtime'
import type { IBinaryReader } from '@protobuf-ts/runtime'
import type { PartialMessage } from '@protobuf-ts/runtime'
import { reflectionMergePartial } from '@protobuf-ts/runtime'
import { MessageType } from '@protobuf-ts/runtime'
/**
 * @generated from protobuf message courses_service.Empty
 */
export interface Empty {}
/**
 * @generated from protobuf message courses_service.BlockId
 */
export interface BlockId {
  /**
   * @generated from protobuf field: int32 id = 1;
   */
  id: number
}
/**
 * @generated from protobuf message courses_service.SpecialityId
 */
export interface SpecialityId {
  /**
   * @generated from protobuf field: int32 id = 1;
   */
  id: number
}
/**
 * @generated from protobuf message courses_service.CourseBlockEntry
 */
export interface CourseBlockEntry {
  /**
   * @generated from protobuf field: int32 courseId = 1;
   */
  courseId: number
  /**
   * @generated from protobuf field: int32 blockId = 2;
   */
  blockId: number
  /**
   * @generated from protobuf field: int32 specialityId = 3;
   */
  specialityId: number
}
/**
 * @generated from protobuf message courses_service.BlockCourses
 */
export interface BlockCourses {
  /**
   * @generated from protobuf field: repeated int32 courseIds = 1;
   */
  courseIds: number[]
  /**
   * @generated from protobuf field: repeated int32 blockIds = 2;
   */
  blockIds: number[]
  /**
   * @generated from protobuf field: repeated int32 specialityIds = 3;
   */
  specialityIds: number[]
}
/**
 * @generated from protobuf message courses_service.ChangeBlockPositionRequest
 */
export interface ChangeBlockPositionRequest {
  /**
   * @generated from protobuf field: int32 blockId = 1;
   */
  blockId: number
  /**
   * @generated from protobuf field: int32 newIndex = 2;
   */
  newIndex: number
}
/**
 * @generated from protobuf message courses_service.CreateBlockRequest
 */
export interface CreateBlockRequest {
  /**
   * @generated from protobuf field: int32 organizationId = 1;
   */
  organizationId: number
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string
  /**
   * @generated from protobuf field: string description = 3;
   */
  description: string
  /**
   * @generated from protobuf field: int64 startTime = 4;
   */
  startTime: bigint
  /**
   * @generated from protobuf field: int64 endTime = 5;
   */
  endTime: bigint
}
/**
 * @generated from protobuf message courses_service.UpdateBlockRequest
 */
export interface UpdateBlockRequest {
  /**
   * @generated from protobuf field: int32 blockId = 1;
   */
  blockId: number
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string
  /**
   * @generated from protobuf field: string description = 3;
   */
  description: string
  /**
   * @generated from protobuf field: int64 startTime = 4;
   */
  startTime: bigint
  /**
   * @generated from protobuf field: int64 endTime = 5;
   */
  endTime: bigint
}
/**
 * @generated from protobuf message courses_service.BlockInfos
 */
export interface BlockInfos {
  /**
   * @generated from protobuf field: repeated int32 blockIds = 1;
   */
  blockIds: number[]
  /**
   * @generated from protobuf field: repeated string names = 2;
   */
  names: string[]
  /**
   * @generated from protobuf field: repeated string descriptions = 3;
   */
  descriptions: string[]
  /**
   * @generated from protobuf field: repeated int64 startTimes = 4;
   */
  startTimes: bigint[]
  /**
   * @generated from protobuf field: repeated int64 endTimes = 5;
   */
  endTimes: bigint[]
}
/**
 * @generated from protobuf message courses_service.CourseId
 */
export interface CourseId {
  /**
   * @generated from protobuf field: int32 courseId = 1;
   */
  courseId: number
}
/**
 * @generated from protobuf message courses_service.CourseInfo
 */
export interface CourseInfo {
  /**
   * @generated from protobuf field: int32 organizationId = 1;
   */
  organizationId: number
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string
  /**
   * @generated from protobuf field: string description = 3;
   */
  description: string
  /**
   * @generated from protobuf field: bool isPublic = 4;
   */
  isPublic: boolean
}
/**
 * @generated from protobuf message courses_service.UpdateCourseInfoRequest
 */
export interface UpdateCourseInfoRequest {
  /**
   * @generated from protobuf field: int32 courseId = 1;
   */
  courseId: number
  /**
   * @generated from protobuf field: courses_service.CourseInfo courseInfo = 2;
   */
  courseInfo?: CourseInfo
}
/**
 * @generated from protobuf message courses_service.ExtendedCourseInfo
 */
export interface ExtendedCourseInfo {
  /**
   * @generated from protobuf field: courses_service.CourseInfo courseInfo = 1;
   */
  courseInfo?: CourseInfo
  /**
   * @generated from protobuf field: repeated int32 sectionIds = 2;
   */
  sectionIds: number[]
  /**
   * @generated from protobuf field: bool isDeleted = 3;
   */
  isDeleted: boolean
}
/**
 * @generated from protobuf message courses_service.GetAllCoursesInfoRequest
 */
export interface GetAllCoursesInfoRequest {
  /**
   * @generated from protobuf field: int32 organizationId = 1;
   */
  organizationId: number
}
/**
 * @generated from protobuf message courses_service.GetAllCoursesInfoResponse
 */
export interface GetAllCoursesInfoResponse {
  /**
   * @generated from protobuf field: repeated int32 courseIds = 1;
   */
  courseIds: number[]
  /**
   * @generated from protobuf field: repeated courses_service.ExtendedCourseInfo courseInfos = 2;
   */
  courseInfos: ExtendedCourseInfo[]
}
/**
 * @generated from protobuf message courses_service.IsPublicResponse
 */
export interface IsPublicResponse {
  /**
   * @generated from protobuf field: bool isPublic = 1;
   */
  isPublic: boolean
}
/**
 * @generated from protobuf message courses_service.ManageUserRequest
 */
export interface ManageUserRequest {
  /**
   * @generated from protobuf field: int32 courseId = 1;
   */
  courseId: number
  /**
   * @generated from protobuf field: int32 userId = 2;
   */
  userId: number
}
/**
 * @generated from protobuf message courses_service.UserEmails
 */
export interface UserEmails {
  /**
   * @generated from protobuf field: repeated string emails = 1;
   */
  emails: string[]
}
/**
 * @generated from protobuf message courses_service.AddStudentsRequest
 */
export interface AddStudentsRequest {
  /**
   * @generated from protobuf field: int32 courseId = 1;
   */
  courseId: number
  /**
   * @generated from protobuf field: courses_service.UserEmails userEmails = 2;
   */
  userEmails?: UserEmails
}
/**
 * @generated from protobuf message courses_service.DeclinedEmails
 */
export interface DeclinedEmails {
  /**
   * @generated from protobuf field: repeated string declinedEmails = 1;
   */
  declinedEmails: string[]
}
/**
 * @generated from protobuf message courses_service.SectionId
 */
export interface SectionId {
  /**
   * @generated from protobuf field: int32 sectionId = 1;
   */
  sectionId: number
}
/**
 * @generated from protobuf message courses_service.SectionInfo
 */
export interface SectionInfo {
  /**
   * @generated from protobuf field: int32 courseId = 1;
   */
  courseId: number
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string
  /**
   * @generated from protobuf field: string description = 3;
   */
  description: string
}
/**
 * @generated from protobuf message courses_service.GetSectionInfoResponse
 */
export interface GetSectionInfoResponse {
  /**
   * @generated from protobuf field: courses_service.SectionInfo sectionInfo = 1;
   */
  sectionInfo?: SectionInfo
  /**
   * @generated from protobuf field: bool isDeleted = 2;
   */
  isDeleted: boolean
}
/**
 * @generated from protobuf message courses_service.CompleteSectionInfo
 */
export interface CompleteSectionInfo {
  /**
   * @generated from protobuf field: int32 sectionId = 1;
   */
  sectionId: number
  /**
   * @generated from protobuf field: courses_service.SectionInfo sectionInfo = 2;
   */
  sectionInfo?: SectionInfo
}
/**
 * @generated from protobuf message courses_service.GetSectionInfosResponse
 */
export interface GetSectionInfosResponse {
  /**
   * @generated from protobuf field: repeated courses_service.CompleteSectionInfo sections = 1;
   */
  sections: CompleteSectionInfo[]
}
/**
 * @generated from protobuf message courses_service.UpdateSectionInfoRequest
 */
export interface UpdateSectionInfoRequest {
  /**
   * @generated from protobuf field: int32 sectionId = 1;
   */
  sectionId: number
  /**
   * @generated from protobuf field: courses_service.SectionInfo sectionInfo = 2;
   */
  sectionInfo?: SectionInfo
}
/**
 * @generated from protobuf message courses_service.UpdateSectionsOrderRequest
 */
export interface UpdateSectionsOrderRequest {
  /**
   * @generated from protobuf field: int32 courseId = 1;
   */
  courseId: number
  /**
   * @generated from protobuf field: repeated int32 sectionIds = 2;
   */
  sectionIds: number[]
}
/**
 * @generated from protobuf message courses_service.Permissions
 */
export interface Permissions {
  /**
   * @generated from protobuf field: bool canUpdate = 1;
   */
  canUpdate: boolean
  /**
   * @generated from protobuf field: bool canView = 2;
   */
  canView: boolean
  /**
   * @generated from protobuf field: bool canManageTeachers = 3;
   */
  canManageTeachers: boolean
  /**
   * @generated from protobuf field: bool canManageStudents = 4;
   */
  canManageStudents: boolean
  /**
   * @generated from protobuf field: bool canManageContent = 5;
   */
  canManageContent: boolean
  /**
   * @generated from protobuf field: bool canViewContent = 6;
   */
  canViewContent: boolean
  /**
   * @generated from protobuf field: bool canViewUserPublicInfo = 7;
   */
  canViewUserPublicInfo: boolean
  /**
   * @generated from protobuf field: bool canViewUserPrivateInfo = 8;
   */
  canViewUserPrivateInfo: boolean
  /**
   * @generated from protobuf field: bool canManageNews = 9;
   */
  canManageNews: boolean
  /**
   * @generated from protobuf field: bool canAddEvents = 10;
   */
  canAddEvents: boolean
  /**
   * @generated from protobuf field: bool canUpdateEvents = 11;
   */
  canUpdateEvents: boolean
  /**
   * @generated from protobuf field: bool canUploadSolutions = 12;
   */
  canUploadSolutions: boolean
}
/**
 * @generated from protobuf message courses_service.GetAllCoursesPermissionsResponse
 */
export interface GetAllCoursesPermissionsResponse {
  /**
   * @generated from protobuf field: repeated int32 courseIds = 1;
   */
  courseIds: number[]
  /**
   * @generated from protobuf field: repeated courses_service.Permissions permissions = 2;
   */
  permissions: Permissions[]
}
/**
 * @generated from protobuf message courses_service.UserIds
 */
export interface UserIds {
  /**
   * @generated from protobuf field: repeated int32 userIds = 1;
   */
  userIds: number[]
}
/**
 * @generated from protobuf message courses_service.OrganizationId
 */
export interface OrganizationId {
  /**
   * @generated from protobuf field: int32 organizationId = 1;
   */
  organizationId: number
}
// @generated message type with reflection information, may provide speed optimized methods
class Empty$Type extends MessageType<Empty> {
  constructor() {
    super('courses_service.Empty', [])
  }
  create(value?: PartialMessage<Empty>): Empty {
    const message = globalThis.Object.create(this.messagePrototype!)
    if (value !== undefined) reflectionMergePartial<Empty>(this, message, value)
    return message
  }
  internalBinaryRead(target?: Empty): Empty {
    return target ?? this.create()
  }
  internalBinaryWrite(message: Empty, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.Empty
 */
export const Empty = new Empty$Type()
// @generated message type with reflection information, may provide speed optimized methods
class BlockId$Type extends MessageType<BlockId> {
  constructor() {
    super('courses_service.BlockId', [{ no: 1, name: 'id', kind: 'scalar', T: 5 /*ScalarType.INT32*/ }])
  }
  create(value?: PartialMessage<BlockId>): BlockId {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.id = 0
    if (value !== undefined) reflectionMergePartial<BlockId>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BlockId): BlockId {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 id */ 1:
          message.id = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: BlockId, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int32(message.id)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.BlockId
 */
export const BlockId = new BlockId$Type()
// @generated message type with reflection information, may provide speed optimized methods
class SpecialityId$Type extends MessageType<SpecialityId> {
  constructor() {
    super('courses_service.SpecialityId', [{ no: 1, name: 'id', kind: 'scalar', T: 5 /*ScalarType.INT32*/ }])
  }
  create(value?: PartialMessage<SpecialityId>): SpecialityId {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.id = 0
    if (value !== undefined) reflectionMergePartial<SpecialityId>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: SpecialityId,
  ): SpecialityId {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 id */ 1:
          message.id = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: SpecialityId, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 id = 1; */
    if (message.id !== 0) writer.tag(1, WireType.Varint).int32(message.id)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.SpecialityId
 */
export const SpecialityId = new SpecialityId$Type()
// @generated message type with reflection information, may provide speed optimized methods
class CourseBlockEntry$Type extends MessageType<CourseBlockEntry> {
  constructor() {
    super('courses_service.CourseBlockEntry', [
      { no: 1, name: 'courseId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'blockId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'specialityId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<CourseBlockEntry>): CourseBlockEntry {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.courseId = 0
    message.blockId = 0
    message.specialityId = 0
    if (value !== undefined) reflectionMergePartial<CourseBlockEntry>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CourseBlockEntry,
  ): CourseBlockEntry {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 courseId */ 1:
          message.courseId = reader.int32()
          break
        case /* int32 blockId */ 2:
          message.blockId = reader.int32()
          break
        case /* int32 specialityId */ 3:
          message.specialityId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: CourseBlockEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 courseId = 1; */
    if (message.courseId !== 0) writer.tag(1, WireType.Varint).int32(message.courseId)
    /* int32 blockId = 2; */
    if (message.blockId !== 0) writer.tag(2, WireType.Varint).int32(message.blockId)
    /* int32 specialityId = 3; */
    if (message.specialityId !== 0) writer.tag(3, WireType.Varint).int32(message.specialityId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.CourseBlockEntry
 */
export const CourseBlockEntry = new CourseBlockEntry$Type()
// @generated message type with reflection information, may provide speed optimized methods
class BlockCourses$Type extends MessageType<BlockCourses> {
  constructor() {
    super('courses_service.BlockCourses', [
      { no: 1, name: 'courseIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'blockIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'specialityIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<BlockCourses>): BlockCourses {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.courseIds = []
    message.blockIds = []
    message.specialityIds = []
    if (value !== undefined) reflectionMergePartial<BlockCourses>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: BlockCourses,
  ): BlockCourses {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 courseIds */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.courseIds.push(reader.int32())
          else message.courseIds.push(reader.int32())
          break
        case /* repeated int32 blockIds */ 2:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.blockIds.push(reader.int32())
          else message.blockIds.push(reader.int32())
          break
        case /* repeated int32 specialityIds */ 3:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.specialityIds.push(reader.int32())
          else message.specialityIds.push(reader.int32())
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: BlockCourses, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* repeated int32 courseIds = 1; */
    if (message.courseIds.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.courseIds.length; i++) writer.int32(message.courseIds[i])
      writer.join()
    }
    /* repeated int32 blockIds = 2; */
    if (message.blockIds.length) {
      writer.tag(2, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.blockIds.length; i++) writer.int32(message.blockIds[i])
      writer.join()
    }
    /* repeated int32 specialityIds = 3; */
    if (message.specialityIds.length) {
      writer.tag(3, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.specialityIds.length; i++) writer.int32(message.specialityIds[i])
      writer.join()
    }
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.BlockCourses
 */
export const BlockCourses = new BlockCourses$Type()
// @generated message type with reflection information, may provide speed optimized methods
class ChangeBlockPositionRequest$Type extends MessageType<ChangeBlockPositionRequest> {
  constructor() {
    super('courses_service.ChangeBlockPositionRequest', [
      { no: 1, name: 'blockId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'newIndex', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<ChangeBlockPositionRequest>): ChangeBlockPositionRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.blockId = 0
    message.newIndex = 0
    if (value !== undefined) reflectionMergePartial<ChangeBlockPositionRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ChangeBlockPositionRequest,
  ): ChangeBlockPositionRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 blockId */ 1:
          message.blockId = reader.int32()
          break
        case /* int32 newIndex */ 2:
          message.newIndex = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: ChangeBlockPositionRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 blockId = 1; */
    if (message.blockId !== 0) writer.tag(1, WireType.Varint).int32(message.blockId)
    /* int32 newIndex = 2; */
    if (message.newIndex !== 0) writer.tag(2, WireType.Varint).int32(message.newIndex)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.ChangeBlockPositionRequest
 */
export const ChangeBlockPositionRequest = new ChangeBlockPositionRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class CreateBlockRequest$Type extends MessageType<CreateBlockRequest> {
  constructor() {
    super('courses_service.CreateBlockRequest', [
      { no: 1, name: 'organizationId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'description', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'startTime', kind: 'scalar', T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
      { no: 5, name: 'endTime', kind: 'scalar', T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
    ])
  }
  create(value?: PartialMessage<CreateBlockRequest>): CreateBlockRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.organizationId = 0
    message.name = ''
    message.description = ''
    message.startTime = 0n
    message.endTime = 0n
    if (value !== undefined) reflectionMergePartial<CreateBlockRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CreateBlockRequest,
  ): CreateBlockRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 organizationId */ 1:
          message.organizationId = reader.int32()
          break
        case /* string name */ 2:
          message.name = reader.string()
          break
        case /* string description */ 3:
          message.description = reader.string()
          break
        case /* int64 startTime */ 4:
          message.startTime = reader.int64().toBigInt()
          break
        case /* int64 endTime */ 5:
          message.endTime = reader.int64().toBigInt()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: CreateBlockRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 organizationId = 1; */
    if (message.organizationId !== 0) writer.tag(1, WireType.Varint).int32(message.organizationId)
    /* string name = 2; */
    if (message.name !== '') writer.tag(2, WireType.LengthDelimited).string(message.name)
    /* string description = 3; */
    if (message.description !== '') writer.tag(3, WireType.LengthDelimited).string(message.description)
    /* int64 startTime = 4; */
    if (message.startTime !== 0n) writer.tag(4, WireType.Varint).int64(message.startTime)
    /* int64 endTime = 5; */
    if (message.endTime !== 0n) writer.tag(5, WireType.Varint).int64(message.endTime)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.CreateBlockRequest
 */
export const CreateBlockRequest = new CreateBlockRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class UpdateBlockRequest$Type extends MessageType<UpdateBlockRequest> {
  constructor() {
    super('courses_service.UpdateBlockRequest', [
      { no: 1, name: 'blockId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'description', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'startTime', kind: 'scalar', T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
      { no: 5, name: 'endTime', kind: 'scalar', T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
    ])
  }
  create(value?: PartialMessage<UpdateBlockRequest>): UpdateBlockRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.blockId = 0
    message.name = ''
    message.description = ''
    message.startTime = 0n
    message.endTime = 0n
    if (value !== undefined) reflectionMergePartial<UpdateBlockRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateBlockRequest,
  ): UpdateBlockRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 blockId */ 1:
          message.blockId = reader.int32()
          break
        case /* string name */ 2:
          message.name = reader.string()
          break
        case /* string description */ 3:
          message.description = reader.string()
          break
        case /* int64 startTime */ 4:
          message.startTime = reader.int64().toBigInt()
          break
        case /* int64 endTime */ 5:
          message.endTime = reader.int64().toBigInt()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: UpdateBlockRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 blockId = 1; */
    if (message.blockId !== 0) writer.tag(1, WireType.Varint).int32(message.blockId)
    /* string name = 2; */
    if (message.name !== '') writer.tag(2, WireType.LengthDelimited).string(message.name)
    /* string description = 3; */
    if (message.description !== '') writer.tag(3, WireType.LengthDelimited).string(message.description)
    /* int64 startTime = 4; */
    if (message.startTime !== 0n) writer.tag(4, WireType.Varint).int64(message.startTime)
    /* int64 endTime = 5; */
    if (message.endTime !== 0n) writer.tag(5, WireType.Varint).int64(message.endTime)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.UpdateBlockRequest
 */
export const UpdateBlockRequest = new UpdateBlockRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class BlockInfos$Type extends MessageType<BlockInfos> {
  constructor() {
    super('courses_service.BlockInfos', [
      { no: 1, name: 'blockIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'names', kind: 'scalar', repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'descriptions', kind: 'scalar', repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'startTimes',
        kind: 'scalar',
        repeat: 1 /*RepeatType.PACKED*/,
        T: 3 /*ScalarType.INT64*/,
        L: 0 /*LongType.BIGINT*/,
      },
      {
        no: 5,
        name: 'endTimes',
        kind: 'scalar',
        repeat: 1 /*RepeatType.PACKED*/,
        T: 3 /*ScalarType.INT64*/,
        L: 0 /*LongType.BIGINT*/,
      },
    ])
  }
  create(value?: PartialMessage<BlockInfos>): BlockInfos {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.blockIds = []
    message.names = []
    message.descriptions = []
    message.startTimes = []
    message.endTimes = []
    if (value !== undefined) reflectionMergePartial<BlockInfos>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: BlockInfos,
  ): BlockInfos {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 blockIds */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.blockIds.push(reader.int32())
          else message.blockIds.push(reader.int32())
          break
        case /* repeated string names */ 2:
          message.names.push(reader.string())
          break
        case /* repeated string descriptions */ 3:
          message.descriptions.push(reader.string())
          break
        case /* repeated int64 startTimes */ 4:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; )
              message.startTimes.push(reader.int64().toBigInt())
          else message.startTimes.push(reader.int64().toBigInt())
          break
        case /* repeated int64 endTimes */ 5:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.endTimes.push(reader.int64().toBigInt())
          else message.endTimes.push(reader.int64().toBigInt())
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: BlockInfos, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* repeated int32 blockIds = 1; */
    if (message.blockIds.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.blockIds.length; i++) writer.int32(message.blockIds[i])
      writer.join()
    }
    /* repeated string names = 2; */
    for (let i = 0; i < message.names.length; i++) writer.tag(2, WireType.LengthDelimited).string(message.names[i])
    /* repeated string descriptions = 3; */
    for (let i = 0; i < message.descriptions.length; i++)
      writer.tag(3, WireType.LengthDelimited).string(message.descriptions[i])
    /* repeated int64 startTimes = 4; */
    if (message.startTimes.length) {
      writer.tag(4, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.startTimes.length; i++) writer.int64(message.startTimes[i])
      writer.join()
    }
    /* repeated int64 endTimes = 5; */
    if (message.endTimes.length) {
      writer.tag(5, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.endTimes.length; i++) writer.int64(message.endTimes[i])
      writer.join()
    }
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.BlockInfos
 */
export const BlockInfos = new BlockInfos$Type()
// @generated message type with reflection information, may provide speed optimized methods
class CourseId$Type extends MessageType<CourseId> {
  constructor() {
    super('courses_service.CourseId', [{ no: 1, name: 'courseId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ }])
  }
  create(value?: PartialMessage<CourseId>): CourseId {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.courseId = 0
    if (value !== undefined) reflectionMergePartial<CourseId>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CourseId): CourseId {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 courseId */ 1:
          message.courseId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: CourseId, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 courseId = 1; */
    if (message.courseId !== 0) writer.tag(1, WireType.Varint).int32(message.courseId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.CourseId
 */
export const CourseId = new CourseId$Type()
// @generated message type with reflection information, may provide speed optimized methods
class CourseInfo$Type extends MessageType<CourseInfo> {
  constructor() {
    super('courses_service.CourseInfo', [
      { no: 1, name: 'organizationId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'description', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'isPublic', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ])
  }
  create(value?: PartialMessage<CourseInfo>): CourseInfo {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.organizationId = 0
    message.name = ''
    message.description = ''
    message.isPublic = false
    if (value !== undefined) reflectionMergePartial<CourseInfo>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CourseInfo,
  ): CourseInfo {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 organizationId */ 1:
          message.organizationId = reader.int32()
          break
        case /* string name */ 2:
          message.name = reader.string()
          break
        case /* string description */ 3:
          message.description = reader.string()
          break
        case /* bool isPublic */ 4:
          message.isPublic = reader.bool()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: CourseInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 organizationId = 1; */
    if (message.organizationId !== 0) writer.tag(1, WireType.Varint).int32(message.organizationId)
    /* string name = 2; */
    if (message.name !== '') writer.tag(2, WireType.LengthDelimited).string(message.name)
    /* string description = 3; */
    if (message.description !== '') writer.tag(3, WireType.LengthDelimited).string(message.description)
    /* bool isPublic = 4; */
    if (message.isPublic !== false) writer.tag(4, WireType.Varint).bool(message.isPublic)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.CourseInfo
 */
export const CourseInfo = new CourseInfo$Type()
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCourseInfoRequest$Type extends MessageType<UpdateCourseInfoRequest> {
  constructor() {
    super('courses_service.UpdateCourseInfoRequest', [
      { no: 1, name: 'courseId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'courseInfo', kind: 'message', T: () => CourseInfo },
    ])
  }
  create(value?: PartialMessage<UpdateCourseInfoRequest>): UpdateCourseInfoRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.courseId = 0
    if (value !== undefined) reflectionMergePartial<UpdateCourseInfoRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateCourseInfoRequest,
  ): UpdateCourseInfoRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 courseId */ 1:
          message.courseId = reader.int32()
          break
        case /* courses_service.CourseInfo courseInfo */ 2:
          message.courseInfo = CourseInfo.internalBinaryRead(reader, reader.uint32(), options, message.courseInfo)
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: UpdateCourseInfoRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 courseId = 1; */
    if (message.courseId !== 0) writer.tag(1, WireType.Varint).int32(message.courseId)
    /* courses_service.CourseInfo courseInfo = 2; */
    if (message.courseInfo)
      CourseInfo.internalBinaryWrite(message.courseInfo, writer.tag(2, WireType.LengthDelimited).fork(), options).join()
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.UpdateCourseInfoRequest
 */
export const UpdateCourseInfoRequest = new UpdateCourseInfoRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class ExtendedCourseInfo$Type extends MessageType<ExtendedCourseInfo> {
  constructor() {
    super('courses_service.ExtendedCourseInfo', [
      { no: 1, name: 'courseInfo', kind: 'message', T: () => CourseInfo },
      { no: 2, name: 'sectionIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'isDeleted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ])
  }
  create(value?: PartialMessage<ExtendedCourseInfo>): ExtendedCourseInfo {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.sectionIds = []
    message.isDeleted = false
    if (value !== undefined) reflectionMergePartial<ExtendedCourseInfo>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ExtendedCourseInfo,
  ): ExtendedCourseInfo {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* courses_service.CourseInfo courseInfo */ 1:
          message.courseInfo = CourseInfo.internalBinaryRead(reader, reader.uint32(), options, message.courseInfo)
          break
        case /* repeated int32 sectionIds */ 2:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.sectionIds.push(reader.int32())
          else message.sectionIds.push(reader.int32())
          break
        case /* bool isDeleted */ 3:
          message.isDeleted = reader.bool()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: ExtendedCourseInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* courses_service.CourseInfo courseInfo = 1; */
    if (message.courseInfo)
      CourseInfo.internalBinaryWrite(message.courseInfo, writer.tag(1, WireType.LengthDelimited).fork(), options).join()
    /* repeated int32 sectionIds = 2; */
    if (message.sectionIds.length) {
      writer.tag(2, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.sectionIds.length; i++) writer.int32(message.sectionIds[i])
      writer.join()
    }
    /* bool isDeleted = 3; */
    if (message.isDeleted !== false) writer.tag(3, WireType.Varint).bool(message.isDeleted)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.ExtendedCourseInfo
 */
export const ExtendedCourseInfo = new ExtendedCourseInfo$Type()
// @generated message type with reflection information, may provide speed optimized methods
class GetAllCoursesInfoRequest$Type extends MessageType<GetAllCoursesInfoRequest> {
  constructor() {
    super('courses_service.GetAllCoursesInfoRequest', [
      { no: 1, name: 'organizationId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<GetAllCoursesInfoRequest>): GetAllCoursesInfoRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.organizationId = 0
    if (value !== undefined) reflectionMergePartial<GetAllCoursesInfoRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetAllCoursesInfoRequest,
  ): GetAllCoursesInfoRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 organizationId */ 1:
          message.organizationId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: GetAllCoursesInfoRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 organizationId = 1; */
    if (message.organizationId !== 0) writer.tag(1, WireType.Varint).int32(message.organizationId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.GetAllCoursesInfoRequest
 */
export const GetAllCoursesInfoRequest = new GetAllCoursesInfoRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class GetAllCoursesInfoResponse$Type extends MessageType<GetAllCoursesInfoResponse> {
  constructor() {
    super('courses_service.GetAllCoursesInfoResponse', [
      { no: 1, name: 'courseIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'courseInfos', kind: 'message', repeat: 1 /*RepeatType.PACKED*/, T: () => ExtendedCourseInfo },
    ])
  }
  create(value?: PartialMessage<GetAllCoursesInfoResponse>): GetAllCoursesInfoResponse {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.courseIds = []
    message.courseInfos = []
    if (value !== undefined) reflectionMergePartial<GetAllCoursesInfoResponse>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetAllCoursesInfoResponse,
  ): GetAllCoursesInfoResponse {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 courseIds */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.courseIds.push(reader.int32())
          else message.courseIds.push(reader.int32())
          break
        case /* repeated courses_service.ExtendedCourseInfo courseInfos */ 2:
          message.courseInfos.push(ExtendedCourseInfo.internalBinaryRead(reader, reader.uint32(), options))
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: GetAllCoursesInfoResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated int32 courseIds = 1; */
    if (message.courseIds.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.courseIds.length; i++) writer.int32(message.courseIds[i])
      writer.join()
    }
    /* repeated courses_service.ExtendedCourseInfo courseInfos = 2; */
    for (let i = 0; i < message.courseInfos.length; i++)
      ExtendedCourseInfo.internalBinaryWrite(
        message.courseInfos[i],
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join()
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.GetAllCoursesInfoResponse
 */
export const GetAllCoursesInfoResponse = new GetAllCoursesInfoResponse$Type()
// @generated message type with reflection information, may provide speed optimized methods
class IsPublicResponse$Type extends MessageType<IsPublicResponse> {
  constructor() {
    super('courses_service.IsPublicResponse', [{ no: 1, name: 'isPublic', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ }])
  }
  create(value?: PartialMessage<IsPublicResponse>): IsPublicResponse {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.isPublic = false
    if (value !== undefined) reflectionMergePartial<IsPublicResponse>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: IsPublicResponse,
  ): IsPublicResponse {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* bool isPublic */ 1:
          message.isPublic = reader.bool()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: IsPublicResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* bool isPublic = 1; */
    if (message.isPublic !== false) writer.tag(1, WireType.Varint).bool(message.isPublic)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.IsPublicResponse
 */
export const IsPublicResponse = new IsPublicResponse$Type()
// @generated message type with reflection information, may provide speed optimized methods
class ManageUserRequest$Type extends MessageType<ManageUserRequest> {
  constructor() {
    super('courses_service.ManageUserRequest', [
      { no: 1, name: 'courseId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'userId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<ManageUserRequest>): ManageUserRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.courseId = 0
    message.userId = 0
    if (value !== undefined) reflectionMergePartial<ManageUserRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ManageUserRequest,
  ): ManageUserRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 courseId */ 1:
          message.courseId = reader.int32()
          break
        case /* int32 userId */ 2:
          message.userId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: ManageUserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 courseId = 1; */
    if (message.courseId !== 0) writer.tag(1, WireType.Varint).int32(message.courseId)
    /* int32 userId = 2; */
    if (message.userId !== 0) writer.tag(2, WireType.Varint).int32(message.userId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.ManageUserRequest
 */
export const ManageUserRequest = new ManageUserRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class UserEmails$Type extends MessageType<UserEmails> {
  constructor() {
    super('courses_service.UserEmails', [
      { no: 1, name: 'emails', kind: 'scalar', repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
    ])
  }
  create(value?: PartialMessage<UserEmails>): UserEmails {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.emails = []
    if (value !== undefined) reflectionMergePartial<UserEmails>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UserEmails,
  ): UserEmails {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated string emails */ 1:
          message.emails.push(reader.string())
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: UserEmails, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* repeated string emails = 1; */
    for (let i = 0; i < message.emails.length; i++) writer.tag(1, WireType.LengthDelimited).string(message.emails[i])
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.UserEmails
 */
export const UserEmails = new UserEmails$Type()
// @generated message type with reflection information, may provide speed optimized methods
class AddStudentsRequest$Type extends MessageType<AddStudentsRequest> {
  constructor() {
    super('courses_service.AddStudentsRequest', [
      { no: 1, name: 'courseId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'userEmails', kind: 'message', T: () => UserEmails },
    ])
  }
  create(value?: PartialMessage<AddStudentsRequest>): AddStudentsRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.courseId = 0
    if (value !== undefined) reflectionMergePartial<AddStudentsRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: AddStudentsRequest,
  ): AddStudentsRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 courseId */ 1:
          message.courseId = reader.int32()
          break
        case /* courses_service.UserEmails userEmails */ 2:
          message.userEmails = UserEmails.internalBinaryRead(reader, reader.uint32(), options, message.userEmails)
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: AddStudentsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 courseId = 1; */
    if (message.courseId !== 0) writer.tag(1, WireType.Varint).int32(message.courseId)
    /* courses_service.UserEmails userEmails = 2; */
    if (message.userEmails)
      UserEmails.internalBinaryWrite(message.userEmails, writer.tag(2, WireType.LengthDelimited).fork(), options).join()
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.AddStudentsRequest
 */
export const AddStudentsRequest = new AddStudentsRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class DeclinedEmails$Type extends MessageType<DeclinedEmails> {
  constructor() {
    super('courses_service.DeclinedEmails', [
      { no: 1, name: 'declinedEmails', kind: 'scalar', repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
    ])
  }
  create(value?: PartialMessage<DeclinedEmails>): DeclinedEmails {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.declinedEmails = []
    if (value !== undefined) reflectionMergePartial<DeclinedEmails>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: DeclinedEmails,
  ): DeclinedEmails {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated string declinedEmails */ 1:
          message.declinedEmails.push(reader.string())
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: DeclinedEmails, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* repeated string declinedEmails = 1; */
    for (let i = 0; i < message.declinedEmails.length; i++)
      writer.tag(1, WireType.LengthDelimited).string(message.declinedEmails[i])
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.DeclinedEmails
 */
export const DeclinedEmails = new DeclinedEmails$Type()
// @generated message type with reflection information, may provide speed optimized methods
class SectionId$Type extends MessageType<SectionId> {
  constructor() {
    super('courses_service.SectionId', [{ no: 1, name: 'sectionId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ }])
  }
  create(value?: PartialMessage<SectionId>): SectionId {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.sectionId = 0
    if (value !== undefined) reflectionMergePartial<SectionId>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SectionId): SectionId {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 sectionId */ 1:
          message.sectionId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: SectionId, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 sectionId = 1; */
    if (message.sectionId !== 0) writer.tag(1, WireType.Varint).int32(message.sectionId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.SectionId
 */
export const SectionId = new SectionId$Type()
// @generated message type with reflection information, may provide speed optimized methods
class SectionInfo$Type extends MessageType<SectionInfo> {
  constructor() {
    super('courses_service.SectionInfo', [
      { no: 1, name: 'courseId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'description', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ])
  }
  create(value?: PartialMessage<SectionInfo>): SectionInfo {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.courseId = 0
    message.name = ''
    message.description = ''
    if (value !== undefined) reflectionMergePartial<SectionInfo>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: SectionInfo,
  ): SectionInfo {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 courseId */ 1:
          message.courseId = reader.int32()
          break
        case /* string name */ 2:
          message.name = reader.string()
          break
        case /* string description */ 3:
          message.description = reader.string()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: SectionInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 courseId = 1; */
    if (message.courseId !== 0) writer.tag(1, WireType.Varint).int32(message.courseId)
    /* string name = 2; */
    if (message.name !== '') writer.tag(2, WireType.LengthDelimited).string(message.name)
    /* string description = 3; */
    if (message.description !== '') writer.tag(3, WireType.LengthDelimited).string(message.description)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.SectionInfo
 */
export const SectionInfo = new SectionInfo$Type()
// @generated message type with reflection information, may provide speed optimized methods
class GetSectionInfoResponse$Type extends MessageType<GetSectionInfoResponse> {
  constructor() {
    super('courses_service.GetSectionInfoResponse', [
      { no: 1, name: 'sectionInfo', kind: 'message', T: () => SectionInfo },
      { no: 2, name: 'isDeleted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ])
  }
  create(value?: PartialMessage<GetSectionInfoResponse>): GetSectionInfoResponse {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.isDeleted = false
    if (value !== undefined) reflectionMergePartial<GetSectionInfoResponse>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetSectionInfoResponse,
  ): GetSectionInfoResponse {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* courses_service.SectionInfo sectionInfo */ 1:
          message.sectionInfo = SectionInfo.internalBinaryRead(reader, reader.uint32(), options, message.sectionInfo)
          break
        case /* bool isDeleted */ 2:
          message.isDeleted = reader.bool()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: GetSectionInfoResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* courses_service.SectionInfo sectionInfo = 1; */
    if (message.sectionInfo)
      SectionInfo.internalBinaryWrite(
        message.sectionInfo,
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join()
    /* bool isDeleted = 2; */
    if (message.isDeleted !== false) writer.tag(2, WireType.Varint).bool(message.isDeleted)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.GetSectionInfoResponse
 */
export const GetSectionInfoResponse = new GetSectionInfoResponse$Type()
// @generated message type with reflection information, may provide speed optimized methods
class CompleteSectionInfo$Type extends MessageType<CompleteSectionInfo> {
  constructor() {
    super('courses_service.CompleteSectionInfo', [
      { no: 1, name: 'sectionId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'sectionInfo', kind: 'message', T: () => SectionInfo },
    ])
  }
  create(value?: PartialMessage<CompleteSectionInfo>): CompleteSectionInfo {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.sectionId = 0
    if (value !== undefined) reflectionMergePartial<CompleteSectionInfo>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CompleteSectionInfo,
  ): CompleteSectionInfo {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 sectionId */ 1:
          message.sectionId = reader.int32()
          break
        case /* courses_service.SectionInfo sectionInfo */ 2:
          message.sectionInfo = SectionInfo.internalBinaryRead(reader, reader.uint32(), options, message.sectionInfo)
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: CompleteSectionInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 sectionId = 1; */
    if (message.sectionId !== 0) writer.tag(1, WireType.Varint).int32(message.sectionId)
    /* courses_service.SectionInfo sectionInfo = 2; */
    if (message.sectionInfo)
      SectionInfo.internalBinaryWrite(
        message.sectionInfo,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join()
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.CompleteSectionInfo
 */
export const CompleteSectionInfo = new CompleteSectionInfo$Type()
// @generated message type with reflection information, may provide speed optimized methods
class GetSectionInfosResponse$Type extends MessageType<GetSectionInfosResponse> {
  constructor() {
    super('courses_service.GetSectionInfosResponse', [
      { no: 1, name: 'sections', kind: 'message', repeat: 1 /*RepeatType.PACKED*/, T: () => CompleteSectionInfo },
    ])
  }
  create(value?: PartialMessage<GetSectionInfosResponse>): GetSectionInfosResponse {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.sections = []
    if (value !== undefined) reflectionMergePartial<GetSectionInfosResponse>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetSectionInfosResponse,
  ): GetSectionInfosResponse {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated courses_service.CompleteSectionInfo sections */ 1:
          message.sections.push(CompleteSectionInfo.internalBinaryRead(reader, reader.uint32(), options))
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: GetSectionInfosResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated courses_service.CompleteSectionInfo sections = 1; */
    for (let i = 0; i < message.sections.length; i++)
      CompleteSectionInfo.internalBinaryWrite(
        message.sections[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join()
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.GetSectionInfosResponse
 */
export const GetSectionInfosResponse = new GetSectionInfosResponse$Type()
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSectionInfoRequest$Type extends MessageType<UpdateSectionInfoRequest> {
  constructor() {
    super('courses_service.UpdateSectionInfoRequest', [
      { no: 1, name: 'sectionId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'sectionInfo', kind: 'message', T: () => SectionInfo },
    ])
  }
  create(value?: PartialMessage<UpdateSectionInfoRequest>): UpdateSectionInfoRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.sectionId = 0
    if (value !== undefined) reflectionMergePartial<UpdateSectionInfoRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateSectionInfoRequest,
  ): UpdateSectionInfoRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 sectionId */ 1:
          message.sectionId = reader.int32()
          break
        case /* courses_service.SectionInfo sectionInfo */ 2:
          message.sectionInfo = SectionInfo.internalBinaryRead(reader, reader.uint32(), options, message.sectionInfo)
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: UpdateSectionInfoRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 sectionId = 1; */
    if (message.sectionId !== 0) writer.tag(1, WireType.Varint).int32(message.sectionId)
    /* courses_service.SectionInfo sectionInfo = 2; */
    if (message.sectionInfo)
      SectionInfo.internalBinaryWrite(
        message.sectionInfo,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join()
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.UpdateSectionInfoRequest
 */
export const UpdateSectionInfoRequest = new UpdateSectionInfoRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSectionsOrderRequest$Type extends MessageType<UpdateSectionsOrderRequest> {
  constructor() {
    super('courses_service.UpdateSectionsOrderRequest', [
      { no: 1, name: 'courseId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'sectionIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<UpdateSectionsOrderRequest>): UpdateSectionsOrderRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.courseId = 0
    message.sectionIds = []
    if (value !== undefined) reflectionMergePartial<UpdateSectionsOrderRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: UpdateSectionsOrderRequest,
  ): UpdateSectionsOrderRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 courseId */ 1:
          message.courseId = reader.int32()
          break
        case /* repeated int32 sectionIds */ 2:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.sectionIds.push(reader.int32())
          else message.sectionIds.push(reader.int32())
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: UpdateSectionsOrderRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 courseId = 1; */
    if (message.courseId !== 0) writer.tag(1, WireType.Varint).int32(message.courseId)
    /* repeated int32 sectionIds = 2; */
    if (message.sectionIds.length) {
      writer.tag(2, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.sectionIds.length; i++) writer.int32(message.sectionIds[i])
      writer.join()
    }
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.UpdateSectionsOrderRequest
 */
export const UpdateSectionsOrderRequest = new UpdateSectionsOrderRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class Permissions$Type extends MessageType<Permissions> {
  constructor() {
    super('courses_service.Permissions', [
      { no: 1, name: 'canUpdate', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 2, name: 'canView', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 3, name: 'canManageTeachers', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 4, name: 'canManageStudents', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 5, name: 'canManageContent', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 6, name: 'canViewContent', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 7, name: 'canViewUserPublicInfo', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 8, name: 'canViewUserPrivateInfo', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 9, name: 'canManageNews', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 10, name: 'canAddEvents', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 11, name: 'canUpdateEvents', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 12, name: 'canUploadSolutions', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ])
  }
  create(value?: PartialMessage<Permissions>): Permissions {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.canUpdate = false
    message.canView = false
    message.canManageTeachers = false
    message.canManageStudents = false
    message.canManageContent = false
    message.canViewContent = false
    message.canViewUserPublicInfo = false
    message.canViewUserPrivateInfo = false
    message.canManageNews = false
    message.canAddEvents = false
    message.canUpdateEvents = false
    message.canUploadSolutions = false
    if (value !== undefined) reflectionMergePartial<Permissions>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: Permissions,
  ): Permissions {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* bool canUpdate */ 1:
          message.canUpdate = reader.bool()
          break
        case /* bool canView */ 2:
          message.canView = reader.bool()
          break
        case /* bool canManageTeachers */ 3:
          message.canManageTeachers = reader.bool()
          break
        case /* bool canManageStudents */ 4:
          message.canManageStudents = reader.bool()
          break
        case /* bool canManageContent */ 5:
          message.canManageContent = reader.bool()
          break
        case /* bool canViewContent */ 6:
          message.canViewContent = reader.bool()
          break
        case /* bool canViewUserPublicInfo */ 7:
          message.canViewUserPublicInfo = reader.bool()
          break
        case /* bool canViewUserPrivateInfo */ 8:
          message.canViewUserPrivateInfo = reader.bool()
          break
        case /* bool canManageNews */ 9:
          message.canManageNews = reader.bool()
          break
        case /* bool canAddEvents */ 10:
          message.canAddEvents = reader.bool()
          break
        case /* bool canUpdateEvents */ 11:
          message.canUpdateEvents = reader.bool()
          break
        case /* bool canUploadSolutions */ 12:
          message.canUploadSolutions = reader.bool()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: Permissions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* bool canUpdate = 1; */
    if (message.canUpdate !== false) writer.tag(1, WireType.Varint).bool(message.canUpdate)
    /* bool canView = 2; */
    if (message.canView !== false) writer.tag(2, WireType.Varint).bool(message.canView)
    /* bool canManageTeachers = 3; */
    if (message.canManageTeachers !== false) writer.tag(3, WireType.Varint).bool(message.canManageTeachers)
    /* bool canManageStudents = 4; */
    if (message.canManageStudents !== false) writer.tag(4, WireType.Varint).bool(message.canManageStudents)
    /* bool canManageContent = 5; */
    if (message.canManageContent !== false) writer.tag(5, WireType.Varint).bool(message.canManageContent)
    /* bool canViewContent = 6; */
    if (message.canViewContent !== false) writer.tag(6, WireType.Varint).bool(message.canViewContent)
    /* bool canViewUserPublicInfo = 7; */
    if (message.canViewUserPublicInfo !== false) writer.tag(7, WireType.Varint).bool(message.canViewUserPublicInfo)
    /* bool canViewUserPrivateInfo = 8; */
    if (message.canViewUserPrivateInfo !== false) writer.tag(8, WireType.Varint).bool(message.canViewUserPrivateInfo)
    /* bool canManageNews = 9; */
    if (message.canManageNews !== false) writer.tag(9, WireType.Varint).bool(message.canManageNews)
    /* bool canAddEvents = 10; */
    if (message.canAddEvents !== false) writer.tag(10, WireType.Varint).bool(message.canAddEvents)
    /* bool canUpdateEvents = 11; */
    if (message.canUpdateEvents !== false) writer.tag(11, WireType.Varint).bool(message.canUpdateEvents)
    /* bool canUploadSolutions = 12; */
    if (message.canUploadSolutions !== false) writer.tag(12, WireType.Varint).bool(message.canUploadSolutions)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.Permissions
 */
export const Permissions = new Permissions$Type()
// @generated message type with reflection information, may provide speed optimized methods
class GetAllCoursesPermissionsResponse$Type extends MessageType<GetAllCoursesPermissionsResponse> {
  constructor() {
    super('courses_service.GetAllCoursesPermissionsResponse', [
      { no: 1, name: 'courseIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'permissions', kind: 'message', repeat: 1 /*RepeatType.PACKED*/, T: () => Permissions },
    ])
  }
  create(value?: PartialMessage<GetAllCoursesPermissionsResponse>): GetAllCoursesPermissionsResponse {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.courseIds = []
    message.permissions = []
    if (value !== undefined) reflectionMergePartial<GetAllCoursesPermissionsResponse>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetAllCoursesPermissionsResponse,
  ): GetAllCoursesPermissionsResponse {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 courseIds */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.courseIds.push(reader.int32())
          else message.courseIds.push(reader.int32())
          break
        case /* repeated courses_service.Permissions permissions */ 2:
          message.permissions.push(Permissions.internalBinaryRead(reader, reader.uint32(), options))
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: GetAllCoursesPermissionsResponse,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated int32 courseIds = 1; */
    if (message.courseIds.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.courseIds.length; i++) writer.int32(message.courseIds[i])
      writer.join()
    }
    /* repeated courses_service.Permissions permissions = 2; */
    for (let i = 0; i < message.permissions.length; i++)
      Permissions.internalBinaryWrite(
        message.permissions[i],
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join()
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.GetAllCoursesPermissionsResponse
 */
export const GetAllCoursesPermissionsResponse = new GetAllCoursesPermissionsResponse$Type()
// @generated message type with reflection information, may provide speed optimized methods
class UserIds$Type extends MessageType<UserIds> {
  constructor() {
    super('courses_service.UserIds', [
      { no: 1, name: 'userIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<UserIds>): UserIds {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.userIds = []
    if (value !== undefined) reflectionMergePartial<UserIds>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserIds): UserIds {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 userIds */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.userIds.push(reader.int32())
          else message.userIds.push(reader.int32())
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: UserIds, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* repeated int32 userIds = 1; */
    if (message.userIds.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.userIds.length; i++) writer.int32(message.userIds[i])
      writer.join()
    }
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.UserIds
 */
export const UserIds = new UserIds$Type()
// @generated message type with reflection information, may provide speed optimized methods
class OrganizationId$Type extends MessageType<OrganizationId> {
  constructor() {
    super('courses_service.OrganizationId', [
      { no: 1, name: 'organizationId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<OrganizationId>): OrganizationId {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.organizationId = 0
    if (value !== undefined) reflectionMergePartial<OrganizationId>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: OrganizationId,
  ): OrganizationId {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 organizationId */ 1:
          message.organizationId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: OrganizationId, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 organizationId = 1; */
    if (message.organizationId !== 0) writer.tag(1, WireType.Varint).int32(message.organizationId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message courses_service.OrganizationId
 */
export const OrganizationId = new OrganizationId$Type()
/**
 * @generated ServiceType for protobuf service courses_service.Courses
 */
export const Courses = new ServiceType('courses_service.Courses', [
  { name: 'CreateBlock', options: {}, I: CreateBlockRequest, O: Empty },
  { name: 'UpdateBlock', options: {}, I: UpdateBlockRequest, O: Empty },
  { name: 'DeleteBlock', options: {}, I: BlockId, O: Empty },
  { name: 'ChangeBlockPosition', options: {}, I: ChangeBlockPositionRequest, O: Empty },
  { name: 'GetOrganizationBlocks', options: {}, I: OrganizationId, O: BlockInfos },
  { name: 'GetOrganizationBlockCourses', options: {}, I: OrganizationId, O: BlockCourses },
  { name: 'AddCourseToBlock', options: {}, I: CourseBlockEntry, O: Empty },
  { name: 'RemoveCourseFromBlock', options: {}, I: CourseBlockEntry, O: Empty },
  { name: 'CreateCourse', options: {}, I: CourseInfo, O: CourseId },
  { name: 'UpdateCourseInfo', options: {}, I: UpdateCourseInfoRequest, O: Empty },
  { name: 'GetCourseInfo', options: {}, I: CourseId, O: ExtendedCourseInfo },
  { name: 'GetAllCoursesInfo', options: {}, I: GetAllCoursesInfoRequest, O: GetAllCoursesInfoResponse },
  { name: 'GetDeletedCoursesInfo', options: {}, I: GetAllCoursesInfoRequest, O: GetAllCoursesInfoResponse },
  { name: 'DeleteCourse', options: {}, I: CourseId, O: Empty },
  { name: 'RestoreCourse', options: {}, I: CourseId, O: Empty },
  { name: 'IsPublic', options: {}, I: CourseId, O: IsPublicResponse },
  { name: 'GetCoursePermissions', options: {}, I: CourseId, O: Permissions },
  { name: 'GetAllCoursesPermissions', options: {}, I: GetAllCoursesInfoRequest, O: GetAllCoursesPermissionsResponse },
  { name: 'AddTeacher', options: {}, I: ManageUserRequest, O: Empty },
  { name: 'AddStudent', options: {}, I: ManageUserRequest, O: Empty },
  { name: 'AddStudents', options: {}, I: AddStudentsRequest, O: DeclinedEmails },
  { name: 'RemoveTeacher', options: {}, I: ManageUserRequest, O: Empty },
  { name: 'RemoveStudent', options: {}, I: ManageUserRequest, O: Empty },
  { name: 'GetTeachers', options: {}, I: CourseId, O: UserIds },
  { name: 'GetStudents', options: {}, I: CourseId, O: UserIds },
  { name: 'CreateSection', options: {}, I: SectionInfo, O: SectionId },
  { name: 'GetAllSectionsInfo', options: {}, I: CourseId, O: GetSectionInfosResponse },
  { name: 'GetDeletedSectionsInfo', options: {}, I: CourseId, O: GetSectionInfosResponse },
  { name: 'GetSectionInfo', options: {}, I: SectionId, O: GetSectionInfoResponse },
  { name: 'UpdateSectionInfo', options: {}, I: UpdateSectionInfoRequest, O: Empty },
  { name: 'UpdateSectionsOrder', options: {}, I: UpdateSectionsOrderRequest, O: Empty },
  { name: 'DeleteSection', options: {}, I: SectionId, O: Empty },
  { name: 'RestoreSection', options: {}, I: SectionId, O: Empty },
])
/**
 * @generated ServiceType for protobuf service courses_service.CoursesPrivate
 */
export const CoursesPrivate = new ServiceType('courses_service.CoursesPrivate', [
  { name: 'GetCourseOrganization', options: {}, I: CourseId, O: OrganizationId },
  { name: 'RestoreCourse', options: {}, I: CourseId, O: Empty },
  { name: 'RemoveSpecialityFromBlocks', options: {}, I: SpecialityId, O: Empty },
])
