export default [
  {
    path: 'contest/:courseId/problem/:slug',
    component: () => import('@/views/global-contests/contest/ContestEditorPage.vue'),
    redirect: { name: 'ViewProblem' },
    children: [
      {
        path: '',
        name: 'ViewProblem',
        component: () => import('@/views/contest/tabs/ProblemInfoTab.vue'),
      },
    ],
  },
  {
    path: 'contest',
    name: 'GlobalContests',
    component: () => import('@/views/global-contests/ContestsHome.vue'),
  },
  {
    path: 'contest/details/:contestId',
    name: 'ContestDetails',
    component: () => import('@/views/global-contests/contest/ContestDetails.vue'),
  },

  {
    path: 'contest/admin',
    redirect: { name: 'ContestManagement' },
    children: [
      {
        path: '',
        name: 'ContestManagement',
        component: () => import('@/views/global-contests/admin/AdminPanel.vue'),
      },
      {
        path: 'configuration',
        children: [
          {
            path: 'problem-set/:setId',
            name: 'ProblemSetConfiguration',
            component: () => import('@/views/global-contests/admin/configuration/ConfigurationPage.vue'),
          },
          {
            path: 'contest/:contestId',
            name: 'ContestConfiguration',
            component: () => import('@/views/global-contests/admin/configuration/ConfigurationPage.vue'),
          },
        ],
      },
      {
        path: '',
        component: () => import('@/components/ReloadOnRouteChange.vue'),
        children: [
          {
            path: 'create',
            redirect: { name: 'CreateGlobalContest' },
            component: () => import('@/views/global-contests/admin/configuration/CreatePage.vue'),
            children: [
              {
                path: 'problem-set',
                name: 'CreateProblemSet',
                component: () => import('@/views/global-contests/admin/configuration/problem-set/BasicSettings.vue'),
              },
              {
                path: 'contest',
                name: 'CreateGlobalContest',
                component: () => import('@/views/global-contests/admin/configuration/contest/ContestSettings.vue'),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'contest/competition/:contestId/:slug',
    name: 'Competition',
    redirect: { name: 'GlobalContestProblems' },
    component: () => import('@/views/global-contests/contest/ContestCompetition.vue'),
    children: [
      {
        path: 'problems',
        name: 'GlobalContestProblems',
        component: () => import('@/views/contest/tabs/ProblemsListTab.vue'),
      },
      {
        path: 'task',
        name: 'GlobalContestTask',
        component: () => import('@/views/global-contests/components/ContestTask.vue'),
      },
      {
        path: 'submissions',
        name: 'GlobalContestSubmissions',
        component: () => import('@/views/contest/tabs/HistoryTab.vue'),
      },
      {
        path: 'results/:solutionId',
        name: 'GlobalContestResults',
        component: () => import('@/views/contest/tabs/ResultsTab.vue'),
      },
      {
        path: 'questions',
        name: 'GlobalContestQuestions',
        component: () => import('@/views/global-contests/components/QuestionBlock.vue'),
      },
      {
        path: 'standings',
        name: 'GlobalContestStandings',
        component: () => import('@/views/global-contests/contest/ContestDashboard.vue'),
      },
    ],
  },
]
