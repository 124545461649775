import { UserInfoPublicClient } from '@/protogen/userinfo_service/userinfo_service.client'
import {
  Empty,
  Id,
  Ids,
  OrganizationSpecialities,
  PendingRequests,
  RequestInfo,
  Statuses,
  Timezone,
  Universities,
  University,
  UpdateSpecialityRequest,
  User,
  UserOrganizationSpecialities,
  Users,
  UsersSpecialityRequest,
} from '@/protogen/userinfo_service/userinfo_service.ts'
import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport'
import { metaOptions } from '@/api/auth.ts'
import { apiRequestWrapper } from '@/helpers/apiWrapper.ts'

const client = new UserInfoPublicClient(
  new GrpcWebFetchTransport({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
  }),
)

export default () => ({
  getUniversities: async (): Promise<Universities> => {
    const request = Empty.create()
    return apiRequestWrapper(async () => {
      const response = await client.getUniversities(request, metaOptions)
      return response.response
    })
  },
  getUniversity: async (id: number): Promise<University> => {
    const request = { id }
    return apiRequestWrapper(async () => {
      const response = await client.getUniversity(request, metaOptions)
      return response.response
    })
  },
  requestApproval: async (
    firstName: string,
    lastName: string,
    phoneNumber: string,
    birthDate: bigint,
    universityId: number,
    graduationYear: number,
    organizationCode: string,
    pictureFileId: Uint8Array,
  ): Promise<void> => {
    const request = {
      firstName,
      lastName,
      phoneNumber,
      birthDate,
      universityId,
      graduationYear,
      organizationCode,
      pictureFileId,
    }
    return apiRequestWrapper(async () => {
      await client.requestApproval(request, metaOptions)
    })
  },
  revokeRequest: async (): Promise<void> => {
    const request = Empty.create()
    return apiRequestWrapper(async () => {
      await client.revokeRequest(request, metaOptions)
    })
  },
  requestInformationChange: async (
    firstName: string,
    lastName: string,
    phoneNumber: string,
    birthDate: bigint,
    universityId: number,
    graduationYear: number,
    pictureFileId: Uint8Array,
  ): Promise<void> => {
    const request = { firstName, lastName, phoneNumber, birthDate, universityId, graduationYear, pictureFileId }
    return apiRequestWrapper(async () => {
      await client.requestInformationChange(request, metaOptions)
    })
  },
  getAccountStatuses: async (emails: string[]): Promise<Statuses> => {
    const request = { emails }
    return apiRequestWrapper(async () => {
      const response = await client.getAccountStatuses(request, metaOptions)
      return response.response
    })
  },
  getRequestInfo: async (): Promise<RequestInfo> => {
    const request = Empty.create()
    return apiRequestWrapper(async () => {
      const response = await client.getRequestInfo(request, metaOptions)
      return response.response
    })
  },
  getUser: async (userId: number, courseId: number): Promise<User> => {
    const request = { userId, courseId }
    return apiRequestWrapper(async () => {
      const response = await client.getUser(request, metaOptions)
      return response.response
    })
  },
  getUsers: async (userIds: number[], courseId: number): Promise<Users> => {
    const request = { userIds, courseId }
    return apiRequestWrapper(async () => {
      const response = await client.getUsers(request, metaOptions)
      return response.response
    })
  },
  getUserIdByEmail: async (email: string, courseId: number): Promise<Id> => {
    const request = { email, courseId }
    return apiRequestWrapper(async () => {
      const response = await client.getUserIdByEmail(request, metaOptions)
      return response.response
    })
  },
  getPendingRequests: async (organizationId: number): Promise<PendingRequests> => {
    const request = { organizationId }
    return apiRequestWrapper(async () => {
      const response = await client.getPendingRequests(request, metaOptions)
      return response.response
    })
  },
  rejectRequest: async (id: number): Promise<void> => {
    const request = { id }
    return apiRequestWrapper(async () => {
      await client.rejectRequest(request, metaOptions)
    })
  },
  approveRequest: async (id: number): Promise<void> => {
    const request = { id }
    return apiRequestWrapper(async () => {
      await client.approveRequest(request, metaOptions)
    })
  },
  enterOrganization: async (code: string): Promise<void> => {
    const request = { code }
    return apiRequestWrapper(async () => {
      await client.enterOrganization(request, metaOptions)
    })
  },
  exitOrganization: async (organizationId: number): Promise<void> => {
    const request = { id: organizationId }
    return apiRequestWrapper(async () => {
      await client.exitOrganization(request, metaOptions)
    })
  },
  addOrganizationMember: async (userId: number, organizationId: number): Promise<void> => {
    const request = { userId, organizationId }
    return apiRequestWrapper(async () => {
      await client.addOrganizationMember(request, metaOptions)
    })
  },
  removeOrganizationMember: async (userId: number, organizationId: number): Promise<void> => {
    const request = { userId, organizationId }
    return apiRequestWrapper(async () => {
      await client.removeOrganizationMember(request, metaOptions)
    })
  },
  getOrganizationMembers: async (id: number): Promise<Users> => {
    const request = { id }
    return apiRequestWrapper(async () => {
      const response = await client.getOrganizationMembers(request, metaOptions)
      return response.response
    })
  },
  getCurrentUserMemberships: async (): Promise<Ids> => {
    const request = Empty.create()
    return apiRequestWrapper(async () => {
      const response = await client.getCurrentUserMemberships(request, metaOptions)
      return response.response
    })
  },
  deleteAllCurrentUserInformation: async (): Promise<void> => {
    const request = Empty.create()
    return apiRequestWrapper(async () => {
      await client.deleteAllCurrentUserInformation(request, metaOptions)
    })
  },
  updateOrganizationSpeciality: async (organizationId: number, specialityId: number): Promise<void> => {
    const request = UpdateSpecialityRequest.create({ organizationId, specialityId })
    return apiRequestWrapper(async () => {
      await client.updateOrganizationSpeciality(request, metaOptions)
    })
  },
  getUsersOrganizationSpecialities: async (
    userIds: number[],
    organizationId: number,
  ): Promise<OrganizationSpecialities> => {
    const request = UsersSpecialityRequest.create({ userIds, organizationId })
    return apiRequestWrapper(async () => {
      const response = await client.getUsersOrganizationSpecialities(request, metaOptions)
      return response.response
    })
  },
  getOrganizationSpecialities: async (ids: number[]): Promise<UserOrganizationSpecialities> => {
    const request = Ids.create({ ids })
    return apiRequestWrapper(async () => {
      const response = await client.getOrganizationSpecialities(request, metaOptions)
      return response.response
    })
  },
  getTimeZonePreference: async (): Promise<Timezone> => {
    const request = Empty.create()
    return apiRequestWrapper(async () => {
      const response = await client.getTimeZonePreference(request, metaOptions)
      return response.response
    })
  },
  setTimeZonePreference: async (timezone: string): Promise<void> => {
    const request = Timezone.create({ timezone })
    return apiRequestWrapper(async () => {
      await client.setTimeZonePreference(request, metaOptions)
    })
  },
})
