// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "grader_service.proto" (package "grader_service", syntax proto3)
// tslint:disable
import { GraderPublic } from './grader_service'
import type { CourseStudentsGrades } from './grader_service'
import type { CourseId } from './grader_service'
import type { PersonalCourseGrades } from './grader_service'
import type { CourseStatistics } from './grader_service'
import type { CourseStatisticsRequest } from './grader_service'
import type { SectionStatistics } from './grader_service'
import type { SectionStatisticsRequest } from './grader_service'
import type { AssessmentResult } from './grader_service'
import type { GetAssessmentResultEntry } from './grader_service'
import type { TaskResult } from './grader_service'
import type { TaskEntry } from './grader_service'
import type { ContestResult } from './grader_service'
import type { GetContestResultEntry } from './grader_service'
import type { FileGrade } from './grader_service'
import type { GetFileGradeEntry } from './grader_service'
import type { GradeFileEntry } from './grader_service'
import type { FileGrades } from './grader_service'
import type { GetAllFileGradesEntry } from './grader_service'
import type { SubmitGradableFileEntry } from './grader_service'
import type { TestDetails } from './grader_service'
import type { OverrideAnswerResultEntry } from './grader_service'
import type { TestResult } from './grader_service'
import type { GetTestResultEntry } from './grader_service'
import type { AllTestResults } from './grader_service'
import type { SubmissionId } from './grader_service'
import type { SubmitTestAnswersEntry } from './grader_service'
import type { RpcTransport } from '@protobuf-ts/runtime-rpc'
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc'
import { GraderPrivate } from './grader_service'
import { stackIntercept } from '@protobuf-ts/runtime-rpc'
import type { Empty } from './grader_service'
import type { UserId } from './grader_service'
import type { UnaryCall } from '@protobuf-ts/runtime-rpc'
import type { RpcOptions } from '@protobuf-ts/runtime-rpc'
/**
 * ———————————————————— Grader Service Private ————————————————————
 *
 * @generated from protobuf service grader_service.GraderPrivate
 */
export interface IGraderPrivateClient {
  /**
   * @generated from protobuf rpc: RemoveUserInformation(grader_service.UserId) returns (grader_service.Empty);
   */
  removeUserInformation(input: UserId, options?: RpcOptions): UnaryCall<UserId, Empty>
}
/**
 * ———————————————————— Grader Service Private ————————————————————
 *
 * @generated from protobuf service grader_service.GraderPrivate
 */
export class GraderPrivateClient implements IGraderPrivateClient, ServiceInfo {
  typeName = GraderPrivate.typeName
  methods = GraderPrivate.methods
  options = GraderPrivate.options
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: RemoveUserInformation(grader_service.UserId) returns (grader_service.Empty);
   */
  removeUserInformation(input: UserId, options?: RpcOptions): UnaryCall<UserId, Empty> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<UserId, Empty>('unary', this._transport, method, opt, input)
  }
}
/**
 * ———————————————————— Grader Service Public ————————————————————
 *
 * @generated from protobuf service grader_service.GraderPublic
 */
export interface IGraderPublicClient {
  /**
   * @generated from protobuf rpc: SubmitTestAnswers(grader_service.SubmitTestAnswersEntry) returns (grader_service.Empty);
   */
  submitTestAnswers(input: SubmitTestAnswersEntry, options?: RpcOptions): UnaryCall<SubmitTestAnswersEntry, Empty>
  /**
   * @generated from protobuf rpc: GetAllTestResults(grader_service.SubmissionId) returns (grader_service.AllTestResults);
   */
  getAllTestResults(input: SubmissionId, options?: RpcOptions): UnaryCall<SubmissionId, AllTestResults>
  /**
   * @generated from protobuf rpc: GetTestResult(grader_service.GetTestResultEntry) returns (grader_service.TestResult);
   */
  getTestResult(input: GetTestResultEntry, options?: RpcOptions): UnaryCall<GetTestResultEntry, TestResult>
  /**
   * @generated from protobuf rpc: OverrideAnswerResult(grader_service.OverrideAnswerResultEntry) returns (grader_service.Empty);
   */
  overrideAnswerResult(
    input: OverrideAnswerResultEntry,
    options?: RpcOptions,
  ): UnaryCall<OverrideAnswerResultEntry, Empty>
  /**
   * @generated from protobuf rpc: RegradeTest(grader_service.SubmissionId) returns (grader_service.Empty);
   */
  regradeTest(input: SubmissionId, options?: RpcOptions): UnaryCall<SubmissionId, Empty>
  /**
   * @generated from protobuf rpc: GetTestDetails(grader_service.SubmissionId) returns (grader_service.TestDetails);
   */
  getTestDetails(input: SubmissionId, options?: RpcOptions): UnaryCall<SubmissionId, TestDetails>
  /**
   * @generated from protobuf rpc: SubmitGradableFile(grader_service.SubmitGradableFileEntry) returns (grader_service.Empty);
   */
  submitGradableFile(input: SubmitGradableFileEntry, options?: RpcOptions): UnaryCall<SubmitGradableFileEntry, Empty>
  /**
   * @generated from protobuf rpc: GetAllFileGrades(grader_service.GetAllFileGradesEntry) returns (grader_service.FileGrades);
   */
  getAllFileGrades(input: GetAllFileGradesEntry, options?: RpcOptions): UnaryCall<GetAllFileGradesEntry, FileGrades>
  /**
   * @generated from protobuf rpc: GradeFile(grader_service.GradeFileEntry) returns (grader_service.Empty);
   */
  gradeFile(input: GradeFileEntry, options?: RpcOptions): UnaryCall<GradeFileEntry, Empty>
  /**
   * @generated from protobuf rpc: GetFileGrade(grader_service.GetFileGradeEntry) returns (grader_service.FileGrade);
   */
  getFileGrade(input: GetFileGradeEntry, options?: RpcOptions): UnaryCall<GetFileGradeEntry, FileGrade>
  /**
   * @generated from protobuf rpc: GetContestResult(grader_service.GetContestResultEntry) returns (grader_service.ContestResult);
   */
  getContestResult(input: GetContestResultEntry, options?: RpcOptions): UnaryCall<GetContestResultEntry, ContestResult>
  /**
   * @generated from protobuf rpc: GetTaskResult(grader_service.TaskEntry) returns (grader_service.TaskResult);
   */
  getTaskResult(input: TaskEntry, options?: RpcOptions): UnaryCall<TaskEntry, TaskResult>
  /**
   * @generated from protobuf rpc: GetAssessmentResult(grader_service.GetAssessmentResultEntry) returns (grader_service.AssessmentResult);
   */
  getAssessmentResult(
    input: GetAssessmentResultEntry,
    options?: RpcOptions,
  ): UnaryCall<GetAssessmentResultEntry, AssessmentResult>
  /**
   * @generated from protobuf rpc: GetSectionStatistics(grader_service.SectionStatisticsRequest) returns (grader_service.SectionStatistics);
   */
  getSectionStatistics(
    input: SectionStatisticsRequest,
    options?: RpcOptions,
  ): UnaryCall<SectionStatisticsRequest, SectionStatistics>
  /**
   * @generated from protobuf rpc: GetCourseStatistics(grader_service.CourseStatisticsRequest) returns (grader_service.CourseStatistics);
   */
  getCourseStatistics(
    input: CourseStatisticsRequest,
    options?: RpcOptions,
  ): UnaryCall<CourseStatisticsRequest, CourseStatistics>
  /**
   * @generated from protobuf rpc: GetPersonalCourseGrades(grader_service.CourseStatisticsRequest) returns (grader_service.PersonalCourseGrades);
   */
  getPersonalCourseGrades(
    input: CourseStatisticsRequest,
    options?: RpcOptions,
  ): UnaryCall<CourseStatisticsRequest, PersonalCourseGrades>
  /**
   * @generated from protobuf rpc: GetCourseStudentsGrades(grader_service.CourseId) returns (grader_service.CourseStudentsGrades);
   */
  getCourseStudentsGrades(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, CourseStudentsGrades>
}
/**
 * ———————————————————— Grader Service Public ————————————————————
 *
 * @generated from protobuf service grader_service.GraderPublic
 */
export class GraderPublicClient implements IGraderPublicClient, ServiceInfo {
  typeName = GraderPublic.typeName
  methods = GraderPublic.methods
  options = GraderPublic.options
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: SubmitTestAnswers(grader_service.SubmitTestAnswersEntry) returns (grader_service.Empty);
   */
  submitTestAnswers(input: SubmitTestAnswersEntry, options?: RpcOptions): UnaryCall<SubmitTestAnswersEntry, Empty> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<SubmitTestAnswersEntry, Empty>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetAllTestResults(grader_service.SubmissionId) returns (grader_service.AllTestResults);
   */
  getAllTestResults(input: SubmissionId, options?: RpcOptions): UnaryCall<SubmissionId, AllTestResults> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<SubmissionId, AllTestResults>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetTestResult(grader_service.GetTestResultEntry) returns (grader_service.TestResult);
   */
  getTestResult(input: GetTestResultEntry, options?: RpcOptions): UnaryCall<GetTestResultEntry, TestResult> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<GetTestResultEntry, TestResult>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: OverrideAnswerResult(grader_service.OverrideAnswerResultEntry) returns (grader_service.Empty);
   */
  overrideAnswerResult(
    input: OverrideAnswerResultEntry,
    options?: RpcOptions,
  ): UnaryCall<OverrideAnswerResultEntry, Empty> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<OverrideAnswerResultEntry, Empty>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: RegradeTest(grader_service.SubmissionId) returns (grader_service.Empty);
   */
  regradeTest(input: SubmissionId, options?: RpcOptions): UnaryCall<SubmissionId, Empty> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<SubmissionId, Empty>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetTestDetails(grader_service.SubmissionId) returns (grader_service.TestDetails);
   */
  getTestDetails(input: SubmissionId, options?: RpcOptions): UnaryCall<SubmissionId, TestDetails> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<SubmissionId, TestDetails>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: SubmitGradableFile(grader_service.SubmitGradableFileEntry) returns (grader_service.Empty);
   */
  submitGradableFile(input: SubmitGradableFileEntry, options?: RpcOptions): UnaryCall<SubmitGradableFileEntry, Empty> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<SubmitGradableFileEntry, Empty>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetAllFileGrades(grader_service.GetAllFileGradesEntry) returns (grader_service.FileGrades);
   */
  getAllFileGrades(input: GetAllFileGradesEntry, options?: RpcOptions): UnaryCall<GetAllFileGradesEntry, FileGrades> {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<GetAllFileGradesEntry, FileGrades>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GradeFile(grader_service.GradeFileEntry) returns (grader_service.Empty);
   */
  gradeFile(input: GradeFileEntry, options?: RpcOptions): UnaryCall<GradeFileEntry, Empty> {
    const method = this.methods[8],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<GradeFileEntry, Empty>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetFileGrade(grader_service.GetFileGradeEntry) returns (grader_service.FileGrade);
   */
  getFileGrade(input: GetFileGradeEntry, options?: RpcOptions): UnaryCall<GetFileGradeEntry, FileGrade> {
    const method = this.methods[9],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<GetFileGradeEntry, FileGrade>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetContestResult(grader_service.GetContestResultEntry) returns (grader_service.ContestResult);
   */
  getContestResult(
    input: GetContestResultEntry,
    options?: RpcOptions,
  ): UnaryCall<GetContestResultEntry, ContestResult> {
    const method = this.methods[10],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<GetContestResultEntry, ContestResult>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetTaskResult(grader_service.TaskEntry) returns (grader_service.TaskResult);
   */
  getTaskResult(input: TaskEntry, options?: RpcOptions): UnaryCall<TaskEntry, TaskResult> {
    const method = this.methods[11],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<TaskEntry, TaskResult>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetAssessmentResult(grader_service.GetAssessmentResultEntry) returns (grader_service.AssessmentResult);
   */
  getAssessmentResult(
    input: GetAssessmentResultEntry,
    options?: RpcOptions,
  ): UnaryCall<GetAssessmentResultEntry, AssessmentResult> {
    const method = this.methods[12],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<GetAssessmentResultEntry, AssessmentResult>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetSectionStatistics(grader_service.SectionStatisticsRequest) returns (grader_service.SectionStatistics);
   */
  getSectionStatistics(
    input: SectionStatisticsRequest,
    options?: RpcOptions,
  ): UnaryCall<SectionStatisticsRequest, SectionStatistics> {
    const method = this.methods[13],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<SectionStatisticsRequest, SectionStatistics>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetCourseStatistics(grader_service.CourseStatisticsRequest) returns (grader_service.CourseStatistics);
   */
  getCourseStatistics(
    input: CourseStatisticsRequest,
    options?: RpcOptions,
  ): UnaryCall<CourseStatisticsRequest, CourseStatistics> {
    const method = this.methods[14],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<CourseStatisticsRequest, CourseStatistics>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetPersonalCourseGrades(grader_service.CourseStatisticsRequest) returns (grader_service.PersonalCourseGrades);
   */
  getPersonalCourseGrades(
    input: CourseStatisticsRequest,
    options?: RpcOptions,
  ): UnaryCall<CourseStatisticsRequest, PersonalCourseGrades> {
    const method = this.methods[15],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<CourseStatisticsRequest, PersonalCourseGrades>('unary', this._transport, method, opt, input)
  }
  /**
   * @generated from protobuf rpc: GetCourseStudentsGrades(grader_service.CourseId) returns (grader_service.CourseStudentsGrades);
   */
  getCourseStudentsGrades(input: CourseId, options?: RpcOptions): UnaryCall<CourseId, CourseStudentsGrades> {
    const method = this.methods[16],
      opt = this._transport.mergeOptions(options)
    return stackIntercept<CourseId, CourseStudentsGrades>('unary', this._transport, method, opt, input)
  }
}
