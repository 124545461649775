import { defineStore } from 'pinia'

import { ElMessage } from 'element-plus'

import { useOrganizationStore } from './organization'
import { State as OrgsStoreState } from './organization'

import api from '@/api/course'
import apiUser from '@/api/userInfo'
import apiNotifications from '@/api/notifications'
import apiGrader from '@/api/grader'

import { CourseForm } from '@/interfaces/forms'
import { CompleteSectionInfo, CourseInfo, Permissions, SectionInfo } from '@/protogen/courses_service/courses_service'
import { ErrorCodeEnum, RpcError } from '@/interfaces/error'
import { HolderType, Subscription } from '@/protogen/notifications_service/notifications_service'
import { ListElement } from '@/interfaces/profileData'

import { showErrorNotification } from '@/helpers/errorNotification'

export interface SectionStated extends CompleteSectionInfo {
  courseId: number
  progress?: number
}

interface DeletedCourseInfo {
  courseId: number
  courseInfo?: CourseInfo
}

export interface CourseStated {
  courseId: number
  sectionIds: number[]
  sections: SectionStated[]
  courseInfo?: CourseInfo | undefined
  permissions?: Permissions
  isSubscribed?: boolean
  courseProgress?: { homeworksPercentage: number; examsPercentage: number; totalPercentage: number }
  courseGrades?: ListElement[]
}

interface State {
  courseList: CourseStated[]
  courseListFetched: boolean
  loading: boolean
  deletedCourses: DeletedCourseInfo[]
  orgsStore: OrgsStoreState
  activeSectionId: number
  activeSectionsIds: number[]
}

const sectionsStatus = localStorage.getItem('sectionsStatus')

export const useCoursesStore = defineStore('courses', {
  state: (): State => {
    return {
      courseList: [],
      courseListFetched: false,
      loading: false,
      deletedCourses: [],
      orgsStore: useOrganizationStore(),
      activeSectionId: 0,
      activeSectionsIds: sectionsStatus ? JSON.parse(sectionsStatus) : [],
    }
  },
  actions: {
    async fetchCourseList() {
      try {
        this.loading = true

        const data = await api().getAllCoursesInfo(this.orgsStore.activeOrganizationId)

        if (data) {
          const courseInfos = data.courseInfos
          this.courseList = courseInfos.map((item, index) => {
            const courseId = data.courseIds[index]

            return { ...item, courseId, isActive: false, sections: [] }
          })

          const response = await api().getAllCoursesPermissions(this.orgsStore.activeOrganizationId)
          if (response) {
            const idToBooleanMap = Object.fromEntries(
              response.courseIds.map((id, index) => [id, response.permissions[index]]),
            )
            this.courseList = this.courseList.map((item) => ({
              ...item,
              permissions: idToBooleanMap[item.courseId] || false,
            }))
          }

          const promises = this.courseList.map(async (course, index) => {
            const subscription: Subscription = {
              holderType: HolderType.Course,
              holderId: course.courseId,
            }
            const isSubscribedRsp = await apiNotifications().isSubscribed(subscription)

            if (isSubscribedRsp) {
              this.courseList[index].isSubscribed = isSubscribedRsp.isSubscribed
            }
          })

          await Promise.all(promises)
        }
      } catch (error) {
        console.error('error fetching course list:', error)
      } finally {
        this.loading = false
        this.courseListFetched = true
        setTimeout(() => {
          this.courseListFetched = false
        }, 1000)
      }
    },
    async fetchCoursesProgress() {
      try {
        this.loading = true
        for (const course of this.courseList) {
          const courseProgress = await apiGrader().getCourseStats(course.courseId, 0)
          if (courseProgress) {
            course.courseProgress = {
              homeworksPercentage: parseFloat((courseProgress.homeworksPercentage * 100).toFixed(2)),
              examsPercentage: parseFloat((courseProgress.examsPercentage * 100).toFixed(2)),
              totalPercentage: parseFloat((courseProgress.coursePercentage * 100).toFixed(2)),
            }
          }
        }
      } catch (error: any) {
        if (error.code != ErrorCodeEnum.INVALID_ARGUMENT) console.error('error fetching courses progress:', error)
      } finally {
        this.loading = false
      }
    },
    async fetchCoursesGrades(courseId: number) {
      try {
        this.loading = true

        const courseProgress = await apiGrader().getPersonalCourseGrades(courseId, 0)
        if (!courseProgress) return

        const courseName = this.courseList.find((course) => course.courseId === courseId)?.courseInfo?.name ?? ''

        const results = courseProgress.assessmentIds.map((_, i) => ({
          title: courseProgress.briefAssessmentInfos[i].title,
          type: courseProgress.briefAssessmentInfos[i].type,
          courseName: courseName,
          score: courseProgress.results[i].score,
          maxScore: courseProgress.results[i].maxScore,
          fileStatus: courseProgress.results[i].fileStatus,
          hasDeadlinePassed: courseProgress.results[i].hasDeadlinePassed,
        }))

        const courseToUpdate = this.courseList.find((course) => course.courseId === courseId)
        if (courseToUpdate) {
          courseToUpdate.courseGrades = results
        }
      } catch (error: any) {
        if (error.code != ErrorCodeEnum.INVALID_ARGUMENT) {
          console.error('Error fetching courses progress:', error)
        }
      } finally {
        this.loading = false
      }
    },
    async fetchCourseProgress(courseId: number) {
      try {
        this.loading = true
        const course = this.courseList.find((course) => course.courseId === courseId)
        if (course) {
          const courseProgress = await apiGrader().getCourseStats(courseId, 0)
          if (courseProgress) {
            course.courseProgress = {
              homeworksPercentage: parseFloat((courseProgress.homeworksPercentage * 100).toFixed(2)),
              examsPercentage: parseFloat((courseProgress.examsPercentage * 100).toFixed(2)),
              totalPercentage: parseFloat((courseProgress.coursePercentage * 100).toFixed(2)),
            }
          }
        }
      } catch (error: any) {
        if (error.code != ErrorCodeEnum.INVALID_ARGUMENT) console.error('error fetching course progress:', error)
      } finally {
        this.loading = false
      }
    },
    async fetchSections(courseId: number) {
      try {
        this.loading = true
        const response = await api().getAllSectionsInfo(courseId)
        if (response.sections) {
          const sectionsInfo = response.sections.map((item: CompleteSectionInfo) => {
            return { ...item, isActive: false, courseId }
          })
          const id = this.courseList.findIndex((course) => course.courseId === courseId)
          if (id !== -1) {
            this.courseList[id].sections = sectionsInfo

            for (const section of this.courseList[id].sections) {
              const sectionProgress = await apiGrader().getSectionStats(section.sectionId, 0)
              if (sectionProgress) {
                section.progress = parseFloat((sectionProgress.sectionPercentage * 100).toFixed(2))
              }
            }

            this.courseList[id].sections.sort((a, b) => {
              return (
                this.courseList[id].sectionIds.indexOf(a.sectionId) -
                this.courseList[id].sectionIds.indexOf(b.sectionId)
              )
            })
          }
        }
      } catch (error: any) {
        if (error.code != ErrorCodeEnum.INVALID_ARGUMENT) console.error(error)
      } finally {
        this.loading = false
      }
    },
    async postCourse(course: CourseForm) {
      try {
        const courseIdRsp = await api().createCourse(course, this.orgsStore.activeOrganizationId)
        if (courseIdRsp) {
          const permissions = await api().getCoursePermissions(courseIdRsp.courseId)
          this.courseList.push({
            courseId: courseIdRsp.courseId,
            courseInfo: {
              organizationId: this.orgsStore.activeOrganizationId,
              name: course.name,
              description: course.description,
              isPublic: course.isPublic,
            },
            sections: [],
            sectionIds: [],
            permissions,
          })
          await this.fetchCourseProgress(courseIdRsp.courseId)
          ElMessage.success('The course has been created!')
        }
      } catch (error) {
        console.error('createCourse', error)
      }
    },
    async toggleCourseVisibility(courseId: number) {
      try {
        const id = this.courseList.findIndex((course) => course.courseId === courseId)
        if (id !== -1) {
          const course = this.courseList[id]
          const visibilty = course.courseInfo?.isPublic
          await api().updateCourseInfo(
            this.orgsStore.activeOrganizationId,
            course.courseInfo?.name || '',
            course.courseInfo?.description || '',
            !visibilty,
            courseId,
          )
          course.courseInfo!.isPublic = !visibilty
          ElMessage.success('Visibility set to ' + !visibilty)
        }
      } catch (error) {
        console.error('toggleCourseVisibility', error)
      }
    },
    async toggleNotifSubscription(courseId: number) {
      const id = this.courseList.findIndex((course) => course.courseId === courseId)

      if (id !== -1 && this.courseList[id].isSubscribed) {
        try {
          await apiNotifications().unsubscribe({ holderType: HolderType.Course, holderId: courseId })
          this.courseList[id].isSubscribed = false
          ElMessage.warning(`Unsubscribed from ${this.courseList[id].courseInfo?.name} notifications`)
        } catch (error) {
          console.error('Error unsubscribing:', error)
        }
      } else if (id !== -1) {
        try {
          await apiNotifications().subscribe({ holderType: HolderType.Course, holderId: courseId })
          this.courseList[id].isSubscribed = true
          ElMessage.success(`Subscribed to ${this.courseList[id].courseInfo?.name} notifications`)
        } catch (error) {
          console.error('Error subscribing:', error)
        }
      } else {
        console.error('Course not found')
      }
    },
    async updateCourseInfo(course: CourseForm, courseId: number) {
      try {
        await api().updateCourseInfo(
          this.orgsStore.activeOrganizationId,
          course.name,
          course.description,
          course.isPublic,
          courseId,
        )

        const id = this.courseList.findIndex((course) => course.courseId === courseId)
        if (id !== -1) {
          this.courseList[id].courseInfo = {
            organizationId: this.orgsStore.activeOrganizationId,
            name: course.name,
            description: course.description,
            isPublic: course.isPublic,
          }
          ElMessage.success('Successfully updated course info!')
        }
      } catch (error) {
        console.error('An error occurred when updating a course: ', error)
      }
    },
    async postSection(section: SectionInfo) {
      try {
        const response = await api().createSection(section.courseId, section.name, section.description)
        if (response) {
          const id = this.courseList.findIndex((course) => course.courseId === section.courseId)
          if (id !== -1) {
            this.courseList[id].sections.push({
              ...response,
              courseId: section.courseId,
              sectionInfo: section,
            })
            ElMessage.success('Successfully created section!')
          }
        }
      } catch (error) {
        console.error('An error occurred when adding a section: ', error)
      }
    },
    async deleteSection(sectionId: number, courseId: number) {
      try {
        await api().deleteSection(sectionId)
        const courseIndex = this.courseList.findIndex((course) => course.courseId === courseId)
        if (courseIndex !== -1) {
          const sectionIndex = this.courseList[courseIndex].sections.findIndex(
            (section) => section.sectionId === sectionId,
          )
          if (sectionIndex !== -1) {
            this.courseList[courseIndex].sections.splice(sectionIndex, 1)
          }
          // TODO: Add to deleted sections
          ElMessage.success('Successfully deleted section!')
        }
      } catch (error) {
        console.error('An error occurred when deleting a section: ', error)
      }
    },
    async updateSectionInfo(sectionId: number, sectionInfo: SectionInfo) {
      try {
        await api().updateSectionInfo(sectionId, sectionInfo)
        ElMessage.success('Success!')
        await this.fetchSections(sectionInfo.courseId)
      } catch (error) {
        console.error('An error occurred while updating the section info:', error)
      }
    },
    async addStudentsToCourse(emails: string[], courseId: number): Promise<void> {
      try {
        const response = await api().addStudents(emails, courseId)
        if (response.declinedEmails.length) {
          ElMessage.error(`Declined emails:${response.declinedEmails.join(',')}`)
        } else {
          ElMessage.success('Students have been added to the course!')
        }
      } catch (error) {
        console.error('Error adding students to course', error)
      }
    },
    async addTeacherToCourse(email: string, courseId: number): Promise<void> {
      try {
        const response = await apiUser().getUserIdByEmail(email, courseId)
        if (response) {
          await api().addTeacher(response.id, courseId)
          ElMessage.success('Teacher has been added to the course!')
        }
      } catch (error: RpcError | any) {
        if (error.code === 'NOT_FOUND') {
          ElMessage.error('User with this email does not exist')
        } else {
          showErrorNotification(error.code)
        }
        console.error('Error adding teacher to course', error)
      }
    },
    async fetchDeletedCourses() {
      try {
        const response = await api().getDeletedCoursesInfo(this.orgsStore.activeOrganizationId)
        if (response) {
          this.deletedCourses = response.courseIds.map((courseId, index) => {
            return {
              courseId,
              courseInfo: response.courseInfos[index].courseInfo,
            }
          })
          return response
        }
      } catch (error) {
        console.error('Error fetching deleted courses:', error)
      }
    },
    async deleteCourse(courseId: number) {
      try {
        await api().deleteCourse(courseId)
        const courseIndex = this.courseList.findIndex((course) => course.courseId === courseId)
        if (courseIndex !== -1) {
          this.deletedCourses.push(this.courseList.splice(courseIndex, 1)[0])
          ElMessage.success('Course has been deleted!')
        }
      } catch (error) {
        console.error('Error deleting course:', error)
      }
    },
    async restoreCourse(courseId: number) {
      try {
        await api().restoreCourse(courseId)
        const courseIndex = this.deletedCourses.findIndex((course) => course.courseId === courseId)
        if (courseIndex !== -1) {
          const course = this.deletedCourses.splice(courseIndex, 1)[0]
          const permissions = await api().getCoursePermissions(course.courseId)
          const isSubscribedRsp = await apiNotifications().isSubscribed({
            holderType: HolderType.Course,
            holderId: course.courseId,
          })
          this.courseList.push({
            courseId: course.courseId,
            courseInfo: course.courseInfo,
            sections: [],
            sectionIds: [],
            permissions,
            isSubscribed: isSubscribedRsp?.isSubscribed,
          })
          ElMessage.success('Course has been restored!')
        }
      } catch (error) {
        console.error('Error restoring course:', error)
      }
    },
    async updateSectionOrder(courseId: number, sectionIds: number[]) {
      try {
        await api().updateSectionsOrder(courseId, sectionIds)
        const courseIndex = this.courseList.findIndex((course) => course.courseId === courseId)
        if (courseIndex) {
          this.courseList[courseIndex].sections.sort((a, b) => {
            return sectionIds.indexOf(a.sectionId) - sectionIds.indexOf(b.sectionId)
          })
          this.courseList[courseIndex].sectionIds = sectionIds
        }
        ElMessage.success("Section's order has been changed!")
      } catch (error) {
        console.error('Error updating section position:', error)
      }
    },
    async ensureCoursesLoaded(orgId: number) {
      if (this.courseList.length === 0) {
        const response = await api().getAllCoursesInfo(orgId)
        if (response) {
          return response.courseIds
        } else {
          return []
        }
      }
      return this.courseList.map((course) => course.courseId)
    },
  },
})
