// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "grader_service.proto" (package "grader_service", syntax proto3)
// tslint:disable
import { ServiceType } from '@protobuf-ts/runtime-rpc'
import type { BinaryWriteOptions } from '@protobuf-ts/runtime'
import type { IBinaryWriter } from '@protobuf-ts/runtime'
import { WireType } from '@protobuf-ts/runtime'
import type { BinaryReadOptions } from '@protobuf-ts/runtime'
import type { IBinaryReader } from '@protobuf-ts/runtime'
import { UnknownFieldHandler } from '@protobuf-ts/runtime'
import type { PartialMessage } from '@protobuf-ts/runtime'
import { reflectionMergePartial } from '@protobuf-ts/runtime'
import { MessageType } from '@protobuf-ts/runtime'
/**
 * @generated from protobuf message grader_service.UserId
 */
export interface UserId {
  /**
   * @generated from protobuf field: int32 userId = 1;
   */
  userId: number
}
/**
 * @generated from protobuf message grader_service.Empty
 */
export interface Empty {}
/**
 * @generated from protobuf message grader_service.SubmissionId
 */
export interface SubmissionId {
  /**
   * @generated from protobuf field: int32 submissionId = 1;
   */
  submissionId: number
}
/**
 * @generated from protobuf message grader_service.SubmitTestAnswersEntry
 */
export interface SubmitTestAnswersEntry {
  /**
   * @generated from protobuf field: int32 submissionId = 1;
   */
  submissionId: number
  /**
   * @generated from protobuf field: bytes submissionFileId = 2;
   */
  submissionFileId: Uint8Array
  /**
   * @generated from protobuf field: bytes testShortConfigFileId = 3;
   */
  testShortConfigFileId: Uint8Array
  /**
   * @generated from protobuf field: int32 emulatedUserId = 4;
   */
  emulatedUserId: number // optional
  /**
   * @generated from protobuf field: int64 emulatedSubmissionTime = 5;
   */
  emulatedSubmissionTime: bigint // optional
}
/**
 * @generated from protobuf message grader_service.GetTestResultEntry
 */
export interface GetTestResultEntry {
  /**
   * @generated from protobuf field: int32 submissionId = 1;
   */
  submissionId: number
  /**
   * @generated from protobuf field: int32 userId = 2;
   */
  userId: number // optional
}
/**
 * @generated from protobuf message grader_service.TestDetails
 */
export interface TestDetails {
  /**
   * @generated from protobuf field: string testFullConfig = 1;
   */
  testFullConfig: string
}
/**
 * @generated from protobuf message grader_service.OverrideAnswerResultEntry
 */
export interface OverrideAnswerResultEntry {
  /**
   * @generated from protobuf field: int32 submissionId = 1;
   */
  submissionId: number
  /**
   * @generated from protobuf field: int32 userId = 2;
   */
  userId: number
  /**
   * @generated from protobuf field: bytes prevResFileId = 3;
   */
  prevResFileId: Uint8Array
  /**
   * @generated from protobuf field: bytes resEditsFileId = 4;
   */
  resEditsFileId: Uint8Array
}
/**
 * @generated from protobuf message grader_service.SubmitGradableFileEntry
 */
export interface SubmitGradableFileEntry {
  /**
   * @generated from protobuf field: int32 assessmentId = 1;
   */
  assessmentId: number
  /**
   * @generated from protobuf field: bytes submissionFileId = 2;
   */
  submissionFileId: Uint8Array
  /**
   * @generated from protobuf field: bool isText = 3;
   */
  isText: boolean
  /**
   * @generated from protobuf field: int32 emulatedUserId = 4;
   */
  emulatedUserId: number // optional
  /**
   * @generated from protobuf field: int64 emulatedSubmissionTime = 5;
   */
  emulatedSubmissionTime: bigint // optional
}
/**
 * @generated from protobuf message grader_service.Filters
 */
export interface Filters {
  /**
   * @generated from protobuf field: grader_service.GradeFilter gradeFilter = 1;
   */
  gradeFilter: GradeFilter
  /**
   * @generated from protobuf field: int32 assessmentId = 2;
   */
  assessmentId: number
  /**
   * @generated from protobuf field: int32 userId = 3;
   */
  userId: number
}
/**
 * @generated from protobuf message grader_service.GetAllFileGradesEntry
 */
export interface GetAllFileGradesEntry {
  /**
   * @generated from protobuf field: int32 courseId = 1;
   */
  courseId: number
  /**
   * @generated from protobuf field: grader_service.Filters filters = 2;
   */
  filters?: Filters
}
/**
 * @generated from protobuf message grader_service.GradeFileEntry
 */
export interface GradeFileEntry {
  /**
   * @generated from protobuf field: int32 assessmentId = 1;
   */
  assessmentId: number
  /**
   * @generated from protobuf field: int32 userId = 2;
   */
  userId: number
  /**
   * @generated from protobuf field: bytes gradedFileId = 3;
   */
  gradedFileId: Uint8Array
  /**
   * @generated from protobuf field: double score = 4;
   */
  score: number
  /**
   * @generated from protobuf field: string comments = 5;
   */
  comments: string
}
/**
 * @generated from protobuf message grader_service.GetFileGradeEntry
 */
export interface GetFileGradeEntry {
  /**
   * @generated from protobuf field: int32 assessmentId = 1;
   */
  assessmentId: number
  /**
   * @generated from protobuf field: int32 userId = 2;
   */
  userId: number // optional
}
/**
 * @generated from protobuf message grader_service.GetContestResultEntry
 */
export interface GetContestResultEntry {
  /**
   * @generated from protobuf field: int32 submissionId = 1;
   */
  submissionId: number
  /**
   * @generated from protobuf field: int32 userId = 2;
   */
  userId: number // optional
}
/**
 * @generated from protobuf message grader_service.TaskEntry
 */
export interface TaskEntry {
  /**
   * @generated from protobuf field: int32 problemId = 1;
   */
  problemId: number
  /**
   * @generated from protobuf field: int32 submissionId = 2;
   */
  submissionId: number
  /**
   * @generated from protobuf field: int32 userId = 3;
   */
  userId: number // optional for GetTaskResult
}
/**
 * @generated from protobuf message grader_service.TaskResult
 */
export interface TaskResult {
  /**
   * @generated from protobuf field: double score = 1;
   */
  score: number
  /**
   * @generated from protobuf field: double maxScore = 2;
   */
  maxScore: number
  /**
   * @generated from protobuf field: bool hasDeadlinePenalty = 3;
   */
  hasDeadlinePenalty: boolean
}
/**
 * @generated from protobuf message grader_service.AllTestResults
 */
export interface AllTestResults {
  /**
   * @generated from protobuf field: repeated grader_service.TestResult testResults = 2;
   */
  testResults: TestResult[]
}
/**
 * @generated from protobuf message grader_service.TestResult
 */
export interface TestResult {
  /**
   * @generated from protobuf field: grader_service.SubmissionStatus submissionStatus = 1;
   */
  submissionStatus: SubmissionStatus
  /**
   * @generated from protobuf field: int32 userId = 2;
   */
  userId: number
  /**
   * @generated from protobuf field: bytes resultFileId = 3;
   */
  resultFileId: Uint8Array
  /**
   * @generated from protobuf field: double maxScore = 4;
   */
  maxScore: number
  /**
   * @generated from protobuf field: double score = 5;
   */
  score: number
  /**
   * @generated from protobuf field: int64 submissionTime = 6;
   */
  submissionTime: bigint
  /**
   * @generated from protobuf field: bytes answersFileId = 7;
   */
  answersFileId: Uint8Array
}
/**
 * @generated from protobuf message grader_service.FileGrades
 */
export interface FileGrades {
  /**
   * @generated from protobuf field: repeated grader_service.FileGrade fileGrades = 1;
   */
  fileGrades: FileGrade[]
  /**
   * @generated from protobuf field: repeated int32 assessmentIds = 2;
   */
  assessmentIds: number[]
  /**
   * @generated from protobuf field: repeated grader_service.BriefAssessmentInfo assessmentInfos = 3;
   */
  assessmentInfos: BriefAssessmentInfo[]
  /**
   * @generated from protobuf field: repeated double fileMaxScores = 4;
   */
  fileMaxScores: number[]
}
/**
 * @generated from protobuf message grader_service.FileGrade
 */
export interface FileGrade {
  /**
   * @generated from protobuf field: grader_service.SubmissionStatus submissionStatus = 1;
   */
  submissionStatus: SubmissionStatus
  /**
   * @generated from protobuf field: int32 userId = 2;
   */
  userId: number
  /**
   * @generated from protobuf field: int32 assessmentId = 3;
   */
  assessmentId: number
  /**
   * @generated from protobuf field: double score = 4;
   */
  score: number
  /**
   * @generated from protobuf field: bool isText = 5;
   */
  isText: boolean
  /**
   * @generated from protobuf field: bytes fileId = 6;
   */
  fileId: Uint8Array
  /**
   * @generated from protobuf field: int64 submissionTime = 7;
   */
  submissionTime: bigint
  /**
   * @generated from protobuf field: string comments = 8;
   */
  comments: string
}
/**
 * @generated from protobuf message grader_service.ContestResult
 */
export interface ContestResult {
  /**
   * @generated from protobuf field: double score = 1;
   */
  score: number
  /**
   * @generated from protobuf field: double maxScore = 2;
   */
  maxScore: number
}
/**
 * @generated from protobuf message grader_service.GetAssessmentResultEntry
 */
export interface GetAssessmentResultEntry {
  /**
   * @generated from protobuf field: int32 assessmentId = 1;
   */
  assessmentId: number
  /**
   * @generated from protobuf field: int32 userId = 2;
   */
  userId: number // optional
}
/**
 * @generated from protobuf message grader_service.AssessmentResult
 */
export interface AssessmentResult {
  /**
   * @generated from protobuf field: double score = 1;
   */
  score: number
  /**
   * @generated from protobuf field: double maxScore = 2;
   */
  maxScore: number
  /**
   * @generated from protobuf field: bool hasDeadlinePassed = 3;
   */
  hasDeadlinePassed: boolean
  /**
   * @generated from protobuf field: grader_service.AssessmentFileStatus fileStatus = 4;
   */
  fileStatus: AssessmentFileStatus
}
/**
 * @generated from protobuf message grader_service.CourseStatisticsRequest
 */
export interface CourseStatisticsRequest {
  /**
   * @generated from protobuf field: int32 courseId = 1;
   */
  courseId: number
  /**
   * @generated from protobuf field: int32 userId = 2;
   */
  userId: number
}
/**
 * @generated from protobuf message grader_service.CourseStatistics
 */
export interface CourseStatistics {
  /**
   * @generated from protobuf field: double homeworksPercentage = 1;
   */
  homeworksPercentage: number
  /**
   * @generated from protobuf field: double examsPercentage = 2;
   */
  examsPercentage: number
  /**
   * @generated from protobuf field: double coursePercentage = 3;
   */
  coursePercentage: number
}
/**
 * @generated from protobuf message grader_service.SectionStatisticsRequest
 */
export interface SectionStatisticsRequest {
  /**
   * @generated from protobuf field: int32 sectionId = 1;
   */
  sectionId: number
  /**
   * @generated from protobuf field: int32 userId = 2;
   */
  userId: number
}
/**
 * @generated from protobuf message grader_service.SectionStatistics
 */
export interface SectionStatistics {
  /**
   * @generated from protobuf field: double homeworksPercentage = 1;
   */
  homeworksPercentage: number
  /**
   * @generated from protobuf field: double examsPercentage = 2;
   */
  examsPercentage: number
  /**
   * @generated from protobuf field: double sectionPercentage = 3;
   */
  sectionPercentage: number
}
/**
 * @generated from protobuf message grader_service.CourseId
 */
export interface CourseId {
  /**
   * @generated from protobuf field: int32 courseId = 1;
   */
  courseId: number
}
/**
 * @generated from protobuf message grader_service.GradeRow
 */
export interface GradeRow {
  /**
   * @generated from protobuf field: int32 userId = 1;
   */
  userId: number
  /**
   * @generated from protobuf field: repeated grader_service.AssessmentResult results = 2;
   */
  results: AssessmentResult[]
  /**
   * @generated from protobuf field: double totalCoursePercentage = 3;
   */
  totalCoursePercentage: number
}
/**
 * @generated from protobuf message grader_service.CourseStudentsGrades
 */
export interface CourseStudentsGrades {
  /**
   * @generated from protobuf field: repeated int32 assessmentIds = 1;
   */
  assessmentIds: number[]
  /**
   * @generated from protobuf field: repeated grader_service.GradeRow rows = 2;
   */
  rows: GradeRow[]
}
/**
 * @generated from protobuf message grader_service.BriefAssessmentInfo
 */
export interface BriefAssessmentInfo {
  /**
   * @generated from protobuf field: string title = 1;
   */
  title: string
  /**
   * @generated from protobuf field: grader_service.AssessmentType type = 2;
   */
  type: AssessmentType
  /**
   * @generated from protobuf field: int64 publicationTime = 3;
   */
  publicationTime: bigint
}
/**
 * @generated from protobuf message grader_service.PersonalCourseGrades
 */
export interface PersonalCourseGrades {
  /**
   * @generated from protobuf field: repeated int32 assessmentIds = 1;
   */
  assessmentIds: number[]
  /**
   * @generated from protobuf field: repeated grader_service.BriefAssessmentInfo briefAssessmentInfos = 2;
   */
  briefAssessmentInfos: BriefAssessmentInfo[]
  /**
   * @generated from protobuf field: repeated grader_service.AssessmentResult results = 3;
   */
  results: AssessmentResult[]
  /**
   * @generated from protobuf field: double totalCoursePercentage = 4;
   */
  totalCoursePercentage: number
}
/**
 * @generated from protobuf enum grader_service.SubmissionStatus
 */
export enum SubmissionStatus {
  /**
   * @generated from protobuf enum value: Submitted = 0;
   */
  Submitted = 0,
  /**
   * @generated from protobuf enum value: Graded = 1;
   */
  Graded = 1,
  /**
   * @generated from protobuf enum value: Corrupted = 2;
   */
  Corrupted = 2,
}
/**
 * @generated from protobuf enum grader_service.GradeFilter
 */
export enum GradeFilter {
  /**
   * @generated from protobuf enum value: All = 0;
   */
  All = 0,
  /**
   * @generated from protobuf enum value: NotSet = 1;
   */
  NotSet = 1,
  /**
   * @generated from protobuf enum value: Set = 2;
   */
  Set = 2,
}
/**
 * @generated from protobuf enum grader_service.AssessmentFileStatus
 */
export enum AssessmentFileStatus {
  /**
   * @generated from protobuf enum value: NoFileField = 0;
   */
  NoFileField = 0,
  /**
   * @generated from protobuf enum value: FileNotSubmitted = 1;
   */
  FileNotSubmitted = 1,
  /**
   * @generated from protobuf enum value: FileSubmitted = 2;
   */
  FileSubmitted = 2,
  /**
   * @generated from protobuf enum value: FileGraded = 3;
   */
  FileGraded = 3,
}
/**
 * @generated from protobuf enum grader_service.AssessmentType
 */
export enum AssessmentType {
  /**
   * @generated from protobuf enum value: Lecture = 0;
   */
  Lecture = 0,
  /**
   * @generated from protobuf enum value: Homework = 1;
   */
  Homework = 1,
  /**
   * @generated from protobuf enum value: Exam = 2;
   */
  Exam = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class UserId$Type extends MessageType<UserId> {
  constructor() {
    super('grader_service.UserId', [{ no: 1, name: 'userId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ }])
  }
  create(value?: PartialMessage<UserId>): UserId {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.userId = 0
    if (value !== undefined) reflectionMergePartial<UserId>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserId): UserId {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 userId */ 1:
          message.userId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: UserId, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 userId = 1; */
    if (message.userId !== 0) writer.tag(1, WireType.Varint).int32(message.userId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.UserId
 */
export const UserId = new UserId$Type()
// @generated message type with reflection information, may provide speed optimized methods
class Empty$Type extends MessageType<Empty> {
  constructor() {
    super('grader_service.Empty', [])
  }
  create(value?: PartialMessage<Empty>): Empty {
    const message = globalThis.Object.create(this.messagePrototype!)
    if (value !== undefined) reflectionMergePartial<Empty>(this, message, value)
    return message
  }
  internalBinaryRead(target?: Empty): Empty {
    return target ?? this.create()
  }
  internalBinaryWrite(message: Empty, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.Empty
 */
export const Empty = new Empty$Type()
// @generated message type with reflection information, may provide speed optimized methods
class SubmissionId$Type extends MessageType<SubmissionId> {
  constructor() {
    super('grader_service.SubmissionId', [{ no: 1, name: 'submissionId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ }])
  }
  create(value?: PartialMessage<SubmissionId>): SubmissionId {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.submissionId = 0
    if (value !== undefined) reflectionMergePartial<SubmissionId>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: SubmissionId,
  ): SubmissionId {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 submissionId */ 1:
          message.submissionId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: SubmissionId, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 submissionId = 1; */
    if (message.submissionId !== 0) writer.tag(1, WireType.Varint).int32(message.submissionId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.SubmissionId
 */
export const SubmissionId = new SubmissionId$Type()
// @generated message type with reflection information, may provide speed optimized methods
class SubmitTestAnswersEntry$Type extends MessageType<SubmitTestAnswersEntry> {
  constructor() {
    super('grader_service.SubmitTestAnswersEntry', [
      { no: 1, name: 'submissionId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'submissionFileId', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
      { no: 3, name: 'testShortConfigFileId', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
      { no: 4, name: 'emulatedUserId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'emulatedSubmissionTime', kind: 'scalar', T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
    ])
  }
  create(value?: PartialMessage<SubmitTestAnswersEntry>): SubmitTestAnswersEntry {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.submissionId = 0
    message.submissionFileId = new Uint8Array(0)
    message.testShortConfigFileId = new Uint8Array(0)
    message.emulatedUserId = 0
    message.emulatedSubmissionTime = 0n
    if (value !== undefined) reflectionMergePartial<SubmitTestAnswersEntry>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: SubmitTestAnswersEntry,
  ): SubmitTestAnswersEntry {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 submissionId */ 1:
          message.submissionId = reader.int32()
          break
        case /* bytes submissionFileId */ 2:
          message.submissionFileId = reader.bytes()
          break
        case /* bytes testShortConfigFileId */ 3:
          message.testShortConfigFileId = reader.bytes()
          break
        case /* int32 emulatedUserId */ 4:
          message.emulatedUserId = reader.int32()
          break
        case /* int64 emulatedSubmissionTime */ 5:
          message.emulatedSubmissionTime = reader.int64().toBigInt()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: SubmitTestAnswersEntry,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 submissionId = 1; */
    if (message.submissionId !== 0) writer.tag(1, WireType.Varint).int32(message.submissionId)
    /* bytes submissionFileId = 2; */
    if (message.submissionFileId.length) writer.tag(2, WireType.LengthDelimited).bytes(message.submissionFileId)
    /* bytes testShortConfigFileId = 3; */
    if (message.testShortConfigFileId.length)
      writer.tag(3, WireType.LengthDelimited).bytes(message.testShortConfigFileId)
    /* int32 emulatedUserId = 4; */
    if (message.emulatedUserId !== 0) writer.tag(4, WireType.Varint).int32(message.emulatedUserId)
    /* int64 emulatedSubmissionTime = 5; */
    if (message.emulatedSubmissionTime !== 0n) writer.tag(5, WireType.Varint).int64(message.emulatedSubmissionTime)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.SubmitTestAnswersEntry
 */
export const SubmitTestAnswersEntry = new SubmitTestAnswersEntry$Type()
// @generated message type with reflection information, may provide speed optimized methods
class GetTestResultEntry$Type extends MessageType<GetTestResultEntry> {
  constructor() {
    super('grader_service.GetTestResultEntry', [
      { no: 1, name: 'submissionId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'userId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<GetTestResultEntry>): GetTestResultEntry {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.submissionId = 0
    message.userId = 0
    if (value !== undefined) reflectionMergePartial<GetTestResultEntry>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetTestResultEntry,
  ): GetTestResultEntry {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 submissionId */ 1:
          message.submissionId = reader.int32()
          break
        case /* int32 userId */ 2:
          message.userId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: GetTestResultEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 submissionId = 1; */
    if (message.submissionId !== 0) writer.tag(1, WireType.Varint).int32(message.submissionId)
    /* int32 userId = 2; */
    if (message.userId !== 0) writer.tag(2, WireType.Varint).int32(message.userId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.GetTestResultEntry
 */
export const GetTestResultEntry = new GetTestResultEntry$Type()
// @generated message type with reflection information, may provide speed optimized methods
class TestDetails$Type extends MessageType<TestDetails> {
  constructor() {
    super('grader_service.TestDetails', [{ no: 1, name: 'testFullConfig', kind: 'scalar', T: 9 /*ScalarType.STRING*/ }])
  }
  create(value?: PartialMessage<TestDetails>): TestDetails {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.testFullConfig = ''
    if (value !== undefined) reflectionMergePartial<TestDetails>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: TestDetails,
  ): TestDetails {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* string testFullConfig */ 1:
          message.testFullConfig = reader.string()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: TestDetails, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* string testFullConfig = 1; */
    if (message.testFullConfig !== '') writer.tag(1, WireType.LengthDelimited).string(message.testFullConfig)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.TestDetails
 */
export const TestDetails = new TestDetails$Type()
// @generated message type with reflection information, may provide speed optimized methods
class OverrideAnswerResultEntry$Type extends MessageType<OverrideAnswerResultEntry> {
  constructor() {
    super('grader_service.OverrideAnswerResultEntry', [
      { no: 1, name: 'submissionId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'userId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'prevResFileId', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
      { no: 4, name: 'resEditsFileId', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
    ])
  }
  create(value?: PartialMessage<OverrideAnswerResultEntry>): OverrideAnswerResultEntry {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.submissionId = 0
    message.userId = 0
    message.prevResFileId = new Uint8Array(0)
    message.resEditsFileId = new Uint8Array(0)
    if (value !== undefined) reflectionMergePartial<OverrideAnswerResultEntry>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: OverrideAnswerResultEntry,
  ): OverrideAnswerResultEntry {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 submissionId */ 1:
          message.submissionId = reader.int32()
          break
        case /* int32 userId */ 2:
          message.userId = reader.int32()
          break
        case /* bytes prevResFileId */ 3:
          message.prevResFileId = reader.bytes()
          break
        case /* bytes resEditsFileId */ 4:
          message.resEditsFileId = reader.bytes()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: OverrideAnswerResultEntry,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 submissionId = 1; */
    if (message.submissionId !== 0) writer.tag(1, WireType.Varint).int32(message.submissionId)
    /* int32 userId = 2; */
    if (message.userId !== 0) writer.tag(2, WireType.Varint).int32(message.userId)
    /* bytes prevResFileId = 3; */
    if (message.prevResFileId.length) writer.tag(3, WireType.LengthDelimited).bytes(message.prevResFileId)
    /* bytes resEditsFileId = 4; */
    if (message.resEditsFileId.length) writer.tag(4, WireType.LengthDelimited).bytes(message.resEditsFileId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.OverrideAnswerResultEntry
 */
export const OverrideAnswerResultEntry = new OverrideAnswerResultEntry$Type()
// @generated message type with reflection information, may provide speed optimized methods
class SubmitGradableFileEntry$Type extends MessageType<SubmitGradableFileEntry> {
  constructor() {
    super('grader_service.SubmitGradableFileEntry', [
      { no: 1, name: 'assessmentId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'submissionFileId', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
      { no: 3, name: 'isText', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 4, name: 'emulatedUserId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'emulatedSubmissionTime', kind: 'scalar', T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
    ])
  }
  create(value?: PartialMessage<SubmitGradableFileEntry>): SubmitGradableFileEntry {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.assessmentId = 0
    message.submissionFileId = new Uint8Array(0)
    message.isText = false
    message.emulatedUserId = 0
    message.emulatedSubmissionTime = 0n
    if (value !== undefined) reflectionMergePartial<SubmitGradableFileEntry>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: SubmitGradableFileEntry,
  ): SubmitGradableFileEntry {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 assessmentId */ 1:
          message.assessmentId = reader.int32()
          break
        case /* bytes submissionFileId */ 2:
          message.submissionFileId = reader.bytes()
          break
        case /* bool isText */ 3:
          message.isText = reader.bool()
          break
        case /* int32 emulatedUserId */ 4:
          message.emulatedUserId = reader.int32()
          break
        case /* int64 emulatedSubmissionTime */ 5:
          message.emulatedSubmissionTime = reader.int64().toBigInt()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: SubmitGradableFileEntry,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 assessmentId = 1; */
    if (message.assessmentId !== 0) writer.tag(1, WireType.Varint).int32(message.assessmentId)
    /* bytes submissionFileId = 2; */
    if (message.submissionFileId.length) writer.tag(2, WireType.LengthDelimited).bytes(message.submissionFileId)
    /* bool isText = 3; */
    if (message.isText !== false) writer.tag(3, WireType.Varint).bool(message.isText)
    /* int32 emulatedUserId = 4; */
    if (message.emulatedUserId !== 0) writer.tag(4, WireType.Varint).int32(message.emulatedUserId)
    /* int64 emulatedSubmissionTime = 5; */
    if (message.emulatedSubmissionTime !== 0n) writer.tag(5, WireType.Varint).int64(message.emulatedSubmissionTime)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.SubmitGradableFileEntry
 */
export const SubmitGradableFileEntry = new SubmitGradableFileEntry$Type()
// @generated message type with reflection information, may provide speed optimized methods
class Filters$Type extends MessageType<Filters> {
  constructor() {
    super('grader_service.Filters', [
      { no: 1, name: 'gradeFilter', kind: 'enum', T: () => ['grader_service.GradeFilter', GradeFilter] },
      { no: 2, name: 'assessmentId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'userId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<Filters>): Filters {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.gradeFilter = 0
    message.assessmentId = 0
    message.userId = 0
    if (value !== undefined) reflectionMergePartial<Filters>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Filters): Filters {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* grader_service.GradeFilter gradeFilter */ 1:
          message.gradeFilter = reader.int32()
          break
        case /* int32 assessmentId */ 2:
          message.assessmentId = reader.int32()
          break
        case /* int32 userId */ 3:
          message.userId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: Filters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* grader_service.GradeFilter gradeFilter = 1; */
    if (message.gradeFilter !== 0) writer.tag(1, WireType.Varint).int32(message.gradeFilter)
    /* int32 assessmentId = 2; */
    if (message.assessmentId !== 0) writer.tag(2, WireType.Varint).int32(message.assessmentId)
    /* int32 userId = 3; */
    if (message.userId !== 0) writer.tag(3, WireType.Varint).int32(message.userId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.Filters
 */
export const Filters = new Filters$Type()
// @generated message type with reflection information, may provide speed optimized methods
class GetAllFileGradesEntry$Type extends MessageType<GetAllFileGradesEntry> {
  constructor() {
    super('grader_service.GetAllFileGradesEntry', [
      { no: 1, name: 'courseId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'filters', kind: 'message', T: () => Filters },
    ])
  }
  create(value?: PartialMessage<GetAllFileGradesEntry>): GetAllFileGradesEntry {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.courseId = 0
    if (value !== undefined) reflectionMergePartial<GetAllFileGradesEntry>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetAllFileGradesEntry,
  ): GetAllFileGradesEntry {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 courseId */ 1:
          message.courseId = reader.int32()
          break
        case /* grader_service.Filters filters */ 2:
          message.filters = Filters.internalBinaryRead(reader, reader.uint32(), options, message.filters)
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: GetAllFileGradesEntry,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 courseId = 1; */
    if (message.courseId !== 0) writer.tag(1, WireType.Varint).int32(message.courseId)
    /* grader_service.Filters filters = 2; */
    if (message.filters)
      Filters.internalBinaryWrite(message.filters, writer.tag(2, WireType.LengthDelimited).fork(), options).join()
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.GetAllFileGradesEntry
 */
export const GetAllFileGradesEntry = new GetAllFileGradesEntry$Type()
// @generated message type with reflection information, may provide speed optimized methods
class GradeFileEntry$Type extends MessageType<GradeFileEntry> {
  constructor() {
    super('grader_service.GradeFileEntry', [
      { no: 1, name: 'assessmentId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'userId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'gradedFileId', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
      { no: 4, name: 'score', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 5, name: 'comments', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ])
  }
  create(value?: PartialMessage<GradeFileEntry>): GradeFileEntry {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.assessmentId = 0
    message.userId = 0
    message.gradedFileId = new Uint8Array(0)
    message.score = 0
    message.comments = ''
    if (value !== undefined) reflectionMergePartial<GradeFileEntry>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GradeFileEntry,
  ): GradeFileEntry {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 assessmentId */ 1:
          message.assessmentId = reader.int32()
          break
        case /* int32 userId */ 2:
          message.userId = reader.int32()
          break
        case /* bytes gradedFileId */ 3:
          message.gradedFileId = reader.bytes()
          break
        case /* double score */ 4:
          message.score = reader.double()
          break
        case /* string comments */ 5:
          message.comments = reader.string()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: GradeFileEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 assessmentId = 1; */
    if (message.assessmentId !== 0) writer.tag(1, WireType.Varint).int32(message.assessmentId)
    /* int32 userId = 2; */
    if (message.userId !== 0) writer.tag(2, WireType.Varint).int32(message.userId)
    /* bytes gradedFileId = 3; */
    if (message.gradedFileId.length) writer.tag(3, WireType.LengthDelimited).bytes(message.gradedFileId)
    /* double score = 4; */
    if (message.score !== 0) writer.tag(4, WireType.Bit64).double(message.score)
    /* string comments = 5; */
    if (message.comments !== '') writer.tag(5, WireType.LengthDelimited).string(message.comments)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.GradeFileEntry
 */
export const GradeFileEntry = new GradeFileEntry$Type()
// @generated message type with reflection information, may provide speed optimized methods
class GetFileGradeEntry$Type extends MessageType<GetFileGradeEntry> {
  constructor() {
    super('grader_service.GetFileGradeEntry', [
      { no: 1, name: 'assessmentId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'userId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<GetFileGradeEntry>): GetFileGradeEntry {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.assessmentId = 0
    message.userId = 0
    if (value !== undefined) reflectionMergePartial<GetFileGradeEntry>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetFileGradeEntry,
  ): GetFileGradeEntry {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 assessmentId */ 1:
          message.assessmentId = reader.int32()
          break
        case /* int32 userId */ 2:
          message.userId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: GetFileGradeEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 assessmentId = 1; */
    if (message.assessmentId !== 0) writer.tag(1, WireType.Varint).int32(message.assessmentId)
    /* int32 userId = 2; */
    if (message.userId !== 0) writer.tag(2, WireType.Varint).int32(message.userId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.GetFileGradeEntry
 */
export const GetFileGradeEntry = new GetFileGradeEntry$Type()
// @generated message type with reflection information, may provide speed optimized methods
class GetContestResultEntry$Type extends MessageType<GetContestResultEntry> {
  constructor() {
    super('grader_service.GetContestResultEntry', [
      { no: 1, name: 'submissionId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'userId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<GetContestResultEntry>): GetContestResultEntry {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.submissionId = 0
    message.userId = 0
    if (value !== undefined) reflectionMergePartial<GetContestResultEntry>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetContestResultEntry,
  ): GetContestResultEntry {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 submissionId */ 1:
          message.submissionId = reader.int32()
          break
        case /* int32 userId */ 2:
          message.userId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: GetContestResultEntry,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 submissionId = 1; */
    if (message.submissionId !== 0) writer.tag(1, WireType.Varint).int32(message.submissionId)
    /* int32 userId = 2; */
    if (message.userId !== 0) writer.tag(2, WireType.Varint).int32(message.userId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.GetContestResultEntry
 */
export const GetContestResultEntry = new GetContestResultEntry$Type()
// @generated message type with reflection information, may provide speed optimized methods
class TaskEntry$Type extends MessageType<TaskEntry> {
  constructor() {
    super('grader_service.TaskEntry', [
      { no: 1, name: 'problemId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'submissionId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'userId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<TaskEntry>): TaskEntry {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.problemId = 0
    message.submissionId = 0
    message.userId = 0
    if (value !== undefined) reflectionMergePartial<TaskEntry>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TaskEntry): TaskEntry {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 problemId */ 1:
          message.problemId = reader.int32()
          break
        case /* int32 submissionId */ 2:
          message.submissionId = reader.int32()
          break
        case /* int32 userId */ 3:
          message.userId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: TaskEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 problemId = 1; */
    if (message.problemId !== 0) writer.tag(1, WireType.Varint).int32(message.problemId)
    /* int32 submissionId = 2; */
    if (message.submissionId !== 0) writer.tag(2, WireType.Varint).int32(message.submissionId)
    /* int32 userId = 3; */
    if (message.userId !== 0) writer.tag(3, WireType.Varint).int32(message.userId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.TaskEntry
 */
export const TaskEntry = new TaskEntry$Type()
// @generated message type with reflection information, may provide speed optimized methods
class TaskResult$Type extends MessageType<TaskResult> {
  constructor() {
    super('grader_service.TaskResult', [
      { no: 1, name: 'score', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 2, name: 'maxScore', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 3, name: 'hasDeadlinePenalty', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ])
  }
  create(value?: PartialMessage<TaskResult>): TaskResult {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.score = 0
    message.maxScore = 0
    message.hasDeadlinePenalty = false
    if (value !== undefined) reflectionMergePartial<TaskResult>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: TaskResult,
  ): TaskResult {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* double score */ 1:
          message.score = reader.double()
          break
        case /* double maxScore */ 2:
          message.maxScore = reader.double()
          break
        case /* bool hasDeadlinePenalty */ 3:
          message.hasDeadlinePenalty = reader.bool()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: TaskResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* double score = 1; */
    if (message.score !== 0) writer.tag(1, WireType.Bit64).double(message.score)
    /* double maxScore = 2; */
    if (message.maxScore !== 0) writer.tag(2, WireType.Bit64).double(message.maxScore)
    /* bool hasDeadlinePenalty = 3; */
    if (message.hasDeadlinePenalty !== false) writer.tag(3, WireType.Varint).bool(message.hasDeadlinePenalty)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.TaskResult
 */
export const TaskResult = new TaskResult$Type()
// @generated message type with reflection information, may provide speed optimized methods
class AllTestResults$Type extends MessageType<AllTestResults> {
  constructor() {
    super('grader_service.AllTestResults', [
      { no: 2, name: 'testResults', kind: 'message', repeat: 1 /*RepeatType.PACKED*/, T: () => TestResult },
    ])
  }
  create(value?: PartialMessage<AllTestResults>): AllTestResults {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.testResults = []
    if (value !== undefined) reflectionMergePartial<AllTestResults>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: AllTestResults,
  ): AllTestResults {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated grader_service.TestResult testResults */ 2:
          message.testResults.push(TestResult.internalBinaryRead(reader, reader.uint32(), options))
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: AllTestResults, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* repeated grader_service.TestResult testResults = 2; */
    for (let i = 0; i < message.testResults.length; i++)
      TestResult.internalBinaryWrite(
        message.testResults[i],
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join()
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.AllTestResults
 */
export const AllTestResults = new AllTestResults$Type()
// @generated message type with reflection information, may provide speed optimized methods
class TestResult$Type extends MessageType<TestResult> {
  constructor() {
    super('grader_service.TestResult', [
      { no: 1, name: 'submissionStatus', kind: 'enum', T: () => ['grader_service.SubmissionStatus', SubmissionStatus] },
      { no: 2, name: 'userId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'resultFileId', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
      { no: 4, name: 'maxScore', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 5, name: 'score', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 6, name: 'submissionTime', kind: 'scalar', T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
      { no: 7, name: 'answersFileId', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
    ])
  }
  create(value?: PartialMessage<TestResult>): TestResult {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.submissionStatus = 0
    message.userId = 0
    message.resultFileId = new Uint8Array(0)
    message.maxScore = 0
    message.score = 0
    message.submissionTime = 0n
    message.answersFileId = new Uint8Array(0)
    if (value !== undefined) reflectionMergePartial<TestResult>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: TestResult,
  ): TestResult {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* grader_service.SubmissionStatus submissionStatus */ 1:
          message.submissionStatus = reader.int32()
          break
        case /* int32 userId */ 2:
          message.userId = reader.int32()
          break
        case /* bytes resultFileId */ 3:
          message.resultFileId = reader.bytes()
          break
        case /* double maxScore */ 4:
          message.maxScore = reader.double()
          break
        case /* double score */ 5:
          message.score = reader.double()
          break
        case /* int64 submissionTime */ 6:
          message.submissionTime = reader.int64().toBigInt()
          break
        case /* bytes answersFileId */ 7:
          message.answersFileId = reader.bytes()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: TestResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* grader_service.SubmissionStatus submissionStatus = 1; */
    if (message.submissionStatus !== 0) writer.tag(1, WireType.Varint).int32(message.submissionStatus)
    /* int32 userId = 2; */
    if (message.userId !== 0) writer.tag(2, WireType.Varint).int32(message.userId)
    /* bytes resultFileId = 3; */
    if (message.resultFileId.length) writer.tag(3, WireType.LengthDelimited).bytes(message.resultFileId)
    /* double maxScore = 4; */
    if (message.maxScore !== 0) writer.tag(4, WireType.Bit64).double(message.maxScore)
    /* double score = 5; */
    if (message.score !== 0) writer.tag(5, WireType.Bit64).double(message.score)
    /* int64 submissionTime = 6; */
    if (message.submissionTime !== 0n) writer.tag(6, WireType.Varint).int64(message.submissionTime)
    /* bytes answersFileId = 7; */
    if (message.answersFileId.length) writer.tag(7, WireType.LengthDelimited).bytes(message.answersFileId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.TestResult
 */
export const TestResult = new TestResult$Type()
// @generated message type with reflection information, may provide speed optimized methods
class FileGrades$Type extends MessageType<FileGrades> {
  constructor() {
    super('grader_service.FileGrades', [
      { no: 1, name: 'fileGrades', kind: 'message', repeat: 1 /*RepeatType.PACKED*/, T: () => FileGrade },
      { no: 2, name: 'assessmentIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: 'assessmentInfos',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BriefAssessmentInfo,
      },
      { no: 4, name: 'fileMaxScores', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 1 /*ScalarType.DOUBLE*/ },
    ])
  }
  create(value?: PartialMessage<FileGrades>): FileGrades {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.fileGrades = []
    message.assessmentIds = []
    message.assessmentInfos = []
    message.fileMaxScores = []
    if (value !== undefined) reflectionMergePartial<FileGrades>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: FileGrades,
  ): FileGrades {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated grader_service.FileGrade fileGrades */ 1:
          message.fileGrades.push(FileGrade.internalBinaryRead(reader, reader.uint32(), options))
          break
        case /* repeated int32 assessmentIds */ 2:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.assessmentIds.push(reader.int32())
          else message.assessmentIds.push(reader.int32())
          break
        case /* repeated grader_service.BriefAssessmentInfo assessmentInfos */ 3:
          message.assessmentInfos.push(BriefAssessmentInfo.internalBinaryRead(reader, reader.uint32(), options))
          break
        case /* repeated double fileMaxScores */ 4:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.fileMaxScores.push(reader.double())
          else message.fileMaxScores.push(reader.double())
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: FileGrades, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* repeated grader_service.FileGrade fileGrades = 1; */
    for (let i = 0; i < message.fileGrades.length; i++)
      FileGrade.internalBinaryWrite(
        message.fileGrades[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join()
    /* repeated int32 assessmentIds = 2; */
    if (message.assessmentIds.length) {
      writer.tag(2, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.assessmentIds.length; i++) writer.int32(message.assessmentIds[i])
      writer.join()
    }
    /* repeated grader_service.BriefAssessmentInfo assessmentInfos = 3; */
    for (let i = 0; i < message.assessmentInfos.length; i++)
      BriefAssessmentInfo.internalBinaryWrite(
        message.assessmentInfos[i],
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join()
    /* repeated double fileMaxScores = 4; */
    if (message.fileMaxScores.length) {
      writer.tag(4, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.fileMaxScores.length; i++) writer.double(message.fileMaxScores[i])
      writer.join()
    }
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.FileGrades
 */
export const FileGrades = new FileGrades$Type()
// @generated message type with reflection information, may provide speed optimized methods
class FileGrade$Type extends MessageType<FileGrade> {
  constructor() {
    super('grader_service.FileGrade', [
      { no: 1, name: 'submissionStatus', kind: 'enum', T: () => ['grader_service.SubmissionStatus', SubmissionStatus] },
      { no: 2, name: 'userId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'assessmentId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'score', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 5, name: 'isText', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 6, name: 'fileId', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
      { no: 7, name: 'submissionTime', kind: 'scalar', T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
      { no: 8, name: 'comments', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ])
  }
  create(value?: PartialMessage<FileGrade>): FileGrade {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.submissionStatus = 0
    message.userId = 0
    message.assessmentId = 0
    message.score = 0
    message.isText = false
    message.fileId = new Uint8Array(0)
    message.submissionTime = 0n
    message.comments = ''
    if (value !== undefined) reflectionMergePartial<FileGrade>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FileGrade): FileGrade {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* grader_service.SubmissionStatus submissionStatus */ 1:
          message.submissionStatus = reader.int32()
          break
        case /* int32 userId */ 2:
          message.userId = reader.int32()
          break
        case /* int32 assessmentId */ 3:
          message.assessmentId = reader.int32()
          break
        case /* double score */ 4:
          message.score = reader.double()
          break
        case /* bool isText */ 5:
          message.isText = reader.bool()
          break
        case /* bytes fileId */ 6:
          message.fileId = reader.bytes()
          break
        case /* int64 submissionTime */ 7:
          message.submissionTime = reader.int64().toBigInt()
          break
        case /* string comments */ 8:
          message.comments = reader.string()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: FileGrade, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* grader_service.SubmissionStatus submissionStatus = 1; */
    if (message.submissionStatus !== 0) writer.tag(1, WireType.Varint).int32(message.submissionStatus)
    /* int32 userId = 2; */
    if (message.userId !== 0) writer.tag(2, WireType.Varint).int32(message.userId)
    /* int32 assessmentId = 3; */
    if (message.assessmentId !== 0) writer.tag(3, WireType.Varint).int32(message.assessmentId)
    /* double score = 4; */
    if (message.score !== 0) writer.tag(4, WireType.Bit64).double(message.score)
    /* bool isText = 5; */
    if (message.isText !== false) writer.tag(5, WireType.Varint).bool(message.isText)
    /* bytes fileId = 6; */
    if (message.fileId.length) writer.tag(6, WireType.LengthDelimited).bytes(message.fileId)
    /* int64 submissionTime = 7; */
    if (message.submissionTime !== 0n) writer.tag(7, WireType.Varint).int64(message.submissionTime)
    /* string comments = 8; */
    if (message.comments !== '') writer.tag(8, WireType.LengthDelimited).string(message.comments)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.FileGrade
 */
export const FileGrade = new FileGrade$Type()
// @generated message type with reflection information, may provide speed optimized methods
class ContestResult$Type extends MessageType<ContestResult> {
  constructor() {
    super('grader_service.ContestResult', [
      { no: 1, name: 'score', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 2, name: 'maxScore', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
    ])
  }
  create(value?: PartialMessage<ContestResult>): ContestResult {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.score = 0
    message.maxScore = 0
    if (value !== undefined) reflectionMergePartial<ContestResult>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ContestResult,
  ): ContestResult {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* double score */ 1:
          message.score = reader.double()
          break
        case /* double maxScore */ 2:
          message.maxScore = reader.double()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: ContestResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* double score = 1; */
    if (message.score !== 0) writer.tag(1, WireType.Bit64).double(message.score)
    /* double maxScore = 2; */
    if (message.maxScore !== 0) writer.tag(2, WireType.Bit64).double(message.maxScore)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.ContestResult
 */
export const ContestResult = new ContestResult$Type()
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentResultEntry$Type extends MessageType<GetAssessmentResultEntry> {
  constructor() {
    super('grader_service.GetAssessmentResultEntry', [
      { no: 1, name: 'assessmentId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'userId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<GetAssessmentResultEntry>): GetAssessmentResultEntry {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.assessmentId = 0
    message.userId = 0
    if (value !== undefined) reflectionMergePartial<GetAssessmentResultEntry>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: GetAssessmentResultEntry,
  ): GetAssessmentResultEntry {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 assessmentId */ 1:
          message.assessmentId = reader.int32()
          break
        case /* int32 userId */ 2:
          message.userId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: GetAssessmentResultEntry,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 assessmentId = 1; */
    if (message.assessmentId !== 0) writer.tag(1, WireType.Varint).int32(message.assessmentId)
    /* int32 userId = 2; */
    if (message.userId !== 0) writer.tag(2, WireType.Varint).int32(message.userId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.GetAssessmentResultEntry
 */
export const GetAssessmentResultEntry = new GetAssessmentResultEntry$Type()
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentResult$Type extends MessageType<AssessmentResult> {
  constructor() {
    super('grader_service.AssessmentResult', [
      { no: 1, name: 'score', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 2, name: 'maxScore', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 3, name: 'hasDeadlinePassed', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 4,
        name: 'fileStatus',
        kind: 'enum',
        T: () => ['grader_service.AssessmentFileStatus', AssessmentFileStatus],
      },
    ])
  }
  create(value?: PartialMessage<AssessmentResult>): AssessmentResult {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.score = 0
    message.maxScore = 0
    message.hasDeadlinePassed = false
    message.fileStatus = 0
    if (value !== undefined) reflectionMergePartial<AssessmentResult>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: AssessmentResult,
  ): AssessmentResult {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* double score */ 1:
          message.score = reader.double()
          break
        case /* double maxScore */ 2:
          message.maxScore = reader.double()
          break
        case /* bool hasDeadlinePassed */ 3:
          message.hasDeadlinePassed = reader.bool()
          break
        case /* grader_service.AssessmentFileStatus fileStatus */ 4:
          message.fileStatus = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: AssessmentResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* double score = 1; */
    if (message.score !== 0) writer.tag(1, WireType.Bit64).double(message.score)
    /* double maxScore = 2; */
    if (message.maxScore !== 0) writer.tag(2, WireType.Bit64).double(message.maxScore)
    /* bool hasDeadlinePassed = 3; */
    if (message.hasDeadlinePassed !== false) writer.tag(3, WireType.Varint).bool(message.hasDeadlinePassed)
    /* grader_service.AssessmentFileStatus fileStatus = 4; */
    if (message.fileStatus !== 0) writer.tag(4, WireType.Varint).int32(message.fileStatus)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.AssessmentResult
 */
export const AssessmentResult = new AssessmentResult$Type()
// @generated message type with reflection information, may provide speed optimized methods
class CourseStatisticsRequest$Type extends MessageType<CourseStatisticsRequest> {
  constructor() {
    super('grader_service.CourseStatisticsRequest', [
      { no: 1, name: 'courseId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'userId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<CourseStatisticsRequest>): CourseStatisticsRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.courseId = 0
    message.userId = 0
    if (value !== undefined) reflectionMergePartial<CourseStatisticsRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CourseStatisticsRequest,
  ): CourseStatisticsRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 courseId */ 1:
          message.courseId = reader.int32()
          break
        case /* int32 userId */ 2:
          message.userId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: CourseStatisticsRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 courseId = 1; */
    if (message.courseId !== 0) writer.tag(1, WireType.Varint).int32(message.courseId)
    /* int32 userId = 2; */
    if (message.userId !== 0) writer.tag(2, WireType.Varint).int32(message.userId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.CourseStatisticsRequest
 */
export const CourseStatisticsRequest = new CourseStatisticsRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class CourseStatistics$Type extends MessageType<CourseStatistics> {
  constructor() {
    super('grader_service.CourseStatistics', [
      { no: 1, name: 'homeworksPercentage', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 2, name: 'examsPercentage', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 3, name: 'coursePercentage', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
    ])
  }
  create(value?: PartialMessage<CourseStatistics>): CourseStatistics {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.homeworksPercentage = 0
    message.examsPercentage = 0
    message.coursePercentage = 0
    if (value !== undefined) reflectionMergePartial<CourseStatistics>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CourseStatistics,
  ): CourseStatistics {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* double homeworksPercentage */ 1:
          message.homeworksPercentage = reader.double()
          break
        case /* double examsPercentage */ 2:
          message.examsPercentage = reader.double()
          break
        case /* double coursePercentage */ 3:
          message.coursePercentage = reader.double()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: CourseStatistics, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* double homeworksPercentage = 1; */
    if (message.homeworksPercentage !== 0) writer.tag(1, WireType.Bit64).double(message.homeworksPercentage)
    /* double examsPercentage = 2; */
    if (message.examsPercentage !== 0) writer.tag(2, WireType.Bit64).double(message.examsPercentage)
    /* double coursePercentage = 3; */
    if (message.coursePercentage !== 0) writer.tag(3, WireType.Bit64).double(message.coursePercentage)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.CourseStatistics
 */
export const CourseStatistics = new CourseStatistics$Type()
// @generated message type with reflection information, may provide speed optimized methods
class SectionStatisticsRequest$Type extends MessageType<SectionStatisticsRequest> {
  constructor() {
    super('grader_service.SectionStatisticsRequest', [
      { no: 1, name: 'sectionId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'userId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ])
  }
  create(value?: PartialMessage<SectionStatisticsRequest>): SectionStatisticsRequest {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.sectionId = 0
    message.userId = 0
    if (value !== undefined) reflectionMergePartial<SectionStatisticsRequest>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: SectionStatisticsRequest,
  ): SectionStatisticsRequest {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 sectionId */ 1:
          message.sectionId = reader.int32()
          break
        case /* int32 userId */ 2:
          message.userId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: SectionStatisticsRequest,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* int32 sectionId = 1; */
    if (message.sectionId !== 0) writer.tag(1, WireType.Varint).int32(message.sectionId)
    /* int32 userId = 2; */
    if (message.userId !== 0) writer.tag(2, WireType.Varint).int32(message.userId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.SectionStatisticsRequest
 */
export const SectionStatisticsRequest = new SectionStatisticsRequest$Type()
// @generated message type with reflection information, may provide speed optimized methods
class SectionStatistics$Type extends MessageType<SectionStatistics> {
  constructor() {
    super('grader_service.SectionStatistics', [
      { no: 1, name: 'homeworksPercentage', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 2, name: 'examsPercentage', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 3, name: 'sectionPercentage', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
    ])
  }
  create(value?: PartialMessage<SectionStatistics>): SectionStatistics {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.homeworksPercentage = 0
    message.examsPercentage = 0
    message.sectionPercentage = 0
    if (value !== undefined) reflectionMergePartial<SectionStatistics>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: SectionStatistics,
  ): SectionStatistics {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* double homeworksPercentage */ 1:
          message.homeworksPercentage = reader.double()
          break
        case /* double examsPercentage */ 2:
          message.examsPercentage = reader.double()
          break
        case /* double sectionPercentage */ 3:
          message.sectionPercentage = reader.double()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: SectionStatistics, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* double homeworksPercentage = 1; */
    if (message.homeworksPercentage !== 0) writer.tag(1, WireType.Bit64).double(message.homeworksPercentage)
    /* double examsPercentage = 2; */
    if (message.examsPercentage !== 0) writer.tag(2, WireType.Bit64).double(message.examsPercentage)
    /* double sectionPercentage = 3; */
    if (message.sectionPercentage !== 0) writer.tag(3, WireType.Bit64).double(message.sectionPercentage)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.SectionStatistics
 */
export const SectionStatistics = new SectionStatistics$Type()
// @generated message type with reflection information, may provide speed optimized methods
class CourseId$Type extends MessageType<CourseId> {
  constructor() {
    super('grader_service.CourseId', [{ no: 1, name: 'courseId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ }])
  }
  create(value?: PartialMessage<CourseId>): CourseId {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.courseId = 0
    if (value !== undefined) reflectionMergePartial<CourseId>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CourseId): CourseId {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 courseId */ 1:
          message.courseId = reader.int32()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: CourseId, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 courseId = 1; */
    if (message.courseId !== 0) writer.tag(1, WireType.Varint).int32(message.courseId)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.CourseId
 */
export const CourseId = new CourseId$Type()
// @generated message type with reflection information, may provide speed optimized methods
class GradeRow$Type extends MessageType<GradeRow> {
  constructor() {
    super('grader_service.GradeRow', [
      { no: 1, name: 'userId', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'results', kind: 'message', repeat: 1 /*RepeatType.PACKED*/, T: () => AssessmentResult },
      { no: 3, name: 'totalCoursePercentage', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
    ])
  }
  create(value?: PartialMessage<GradeRow>): GradeRow {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.userId = 0
    message.results = []
    message.totalCoursePercentage = 0
    if (value !== undefined) reflectionMergePartial<GradeRow>(this, message, value)
    return message
  }
  internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GradeRow): GradeRow {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* int32 userId */ 1:
          message.userId = reader.int32()
          break
        case /* repeated grader_service.AssessmentResult results */ 2:
          message.results.push(AssessmentResult.internalBinaryRead(reader, reader.uint32(), options))
          break
        case /* double totalCoursePercentage */ 3:
          message.totalCoursePercentage = reader.double()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: GradeRow, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* int32 userId = 1; */
    if (message.userId !== 0) writer.tag(1, WireType.Varint).int32(message.userId)
    /* repeated grader_service.AssessmentResult results = 2; */
    for (let i = 0; i < message.results.length; i++)
      AssessmentResult.internalBinaryWrite(
        message.results[i],
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join()
    /* double totalCoursePercentage = 3; */
    if (message.totalCoursePercentage !== 0) writer.tag(3, WireType.Bit64).double(message.totalCoursePercentage)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.GradeRow
 */
export const GradeRow = new GradeRow$Type()
// @generated message type with reflection information, may provide speed optimized methods
class CourseStudentsGrades$Type extends MessageType<CourseStudentsGrades> {
  constructor() {
    super('grader_service.CourseStudentsGrades', [
      { no: 1, name: 'assessmentIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'rows', kind: 'message', repeat: 1 /*RepeatType.PACKED*/, T: () => GradeRow },
    ])
  }
  create(value?: PartialMessage<CourseStudentsGrades>): CourseStudentsGrades {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.assessmentIds = []
    message.rows = []
    if (value !== undefined) reflectionMergePartial<CourseStudentsGrades>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CourseStudentsGrades,
  ): CourseStudentsGrades {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 assessmentIds */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.assessmentIds.push(reader.int32())
          else message.assessmentIds.push(reader.int32())
          break
        case /* repeated grader_service.GradeRow rows */ 2:
          message.rows.push(GradeRow.internalBinaryRead(reader, reader.uint32(), options))
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: CourseStudentsGrades,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated int32 assessmentIds = 1; */
    if (message.assessmentIds.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.assessmentIds.length; i++) writer.int32(message.assessmentIds[i])
      writer.join()
    }
    /* repeated grader_service.GradeRow rows = 2; */
    for (let i = 0; i < message.rows.length; i++)
      GradeRow.internalBinaryWrite(message.rows[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join()
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.CourseStudentsGrades
 */
export const CourseStudentsGrades = new CourseStudentsGrades$Type()
// @generated message type with reflection information, may provide speed optimized methods
class BriefAssessmentInfo$Type extends MessageType<BriefAssessmentInfo> {
  constructor() {
    super('grader_service.BriefAssessmentInfo', [
      { no: 1, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'type', kind: 'enum', T: () => ['grader_service.AssessmentType', AssessmentType] },
      { no: 3, name: 'publicationTime', kind: 'scalar', T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
    ])
  }
  create(value?: PartialMessage<BriefAssessmentInfo>): BriefAssessmentInfo {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.title = ''
    message.type = 0
    message.publicationTime = 0n
    if (value !== undefined) reflectionMergePartial<BriefAssessmentInfo>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: BriefAssessmentInfo,
  ): BriefAssessmentInfo {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* string title */ 1:
          message.title = reader.string()
          break
        case /* grader_service.AssessmentType type */ 2:
          message.type = reader.int32()
          break
        case /* int64 publicationTime */ 3:
          message.publicationTime = reader.int64().toBigInt()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(message: BriefAssessmentInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
    /* string title = 1; */
    if (message.title !== '') writer.tag(1, WireType.LengthDelimited).string(message.title)
    /* grader_service.AssessmentType type = 2; */
    if (message.type !== 0) writer.tag(2, WireType.Varint).int32(message.type)
    /* int64 publicationTime = 3; */
    if (message.publicationTime !== 0n) writer.tag(3, WireType.Varint).int64(message.publicationTime)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.BriefAssessmentInfo
 */
export const BriefAssessmentInfo = new BriefAssessmentInfo$Type()
// @generated message type with reflection information, may provide speed optimized methods
class PersonalCourseGrades$Type extends MessageType<PersonalCourseGrades> {
  constructor() {
    super('grader_service.PersonalCourseGrades', [
      { no: 1, name: 'assessmentIds', kind: 'scalar', repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'briefAssessmentInfos',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BriefAssessmentInfo,
      },
      { no: 3, name: 'results', kind: 'message', repeat: 1 /*RepeatType.PACKED*/, T: () => AssessmentResult },
      { no: 4, name: 'totalCoursePercentage', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
    ])
  }
  create(value?: PartialMessage<PersonalCourseGrades>): PersonalCourseGrades {
    const message = globalThis.Object.create(this.messagePrototype!)
    message.assessmentIds = []
    message.briefAssessmentInfos = []
    message.results = []
    message.totalCoursePercentage = 0
    if (value !== undefined) reflectionMergePartial<PersonalCourseGrades>(this, message, value)
    return message
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: PersonalCourseGrades,
  ): PersonalCourseGrades {
    let message = target ?? this.create(),
      end = reader.pos + length
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag()
      switch (fieldNo) {
        case /* repeated int32 assessmentIds */ 1:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; ) message.assessmentIds.push(reader.int32())
          else message.assessmentIds.push(reader.int32())
          break
        case /* repeated grader_service.BriefAssessmentInfo briefAssessmentInfos */ 2:
          message.briefAssessmentInfos.push(BriefAssessmentInfo.internalBinaryRead(reader, reader.uint32(), options))
          break
        case /* repeated grader_service.AssessmentResult results */ 3:
          message.results.push(AssessmentResult.internalBinaryRead(reader, reader.uint32(), options))
          break
        case /* double totalCoursePercentage */ 4:
          message.totalCoursePercentage = reader.double()
          break
        default:
          let u = options.readUnknownField
          if (u === 'throw')
            throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`)
          let d = reader.skip(wireType)
          if (u !== false) (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d)
      }
    }
    return message
  }
  internalBinaryWrite(
    message: PersonalCourseGrades,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated int32 assessmentIds = 1; */
    if (message.assessmentIds.length) {
      writer.tag(1, WireType.LengthDelimited).fork()
      for (let i = 0; i < message.assessmentIds.length; i++) writer.int32(message.assessmentIds[i])
      writer.join()
    }
    /* repeated grader_service.BriefAssessmentInfo briefAssessmentInfos = 2; */
    for (let i = 0; i < message.briefAssessmentInfos.length; i++)
      BriefAssessmentInfo.internalBinaryWrite(
        message.briefAssessmentInfos[i],
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join()
    /* repeated grader_service.AssessmentResult results = 3; */
    for (let i = 0; i < message.results.length; i++)
      AssessmentResult.internalBinaryWrite(
        message.results[i],
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join()
    /* double totalCoursePercentage = 4; */
    if (message.totalCoursePercentage !== 0) writer.tag(4, WireType.Bit64).double(message.totalCoursePercentage)
    let u = options.writeUnknownFields
    if (u !== false) (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer)
    return writer
  }
}
/**
 * @generated MessageType for protobuf message grader_service.PersonalCourseGrades
 */
export const PersonalCourseGrades = new PersonalCourseGrades$Type()
/**
 * @generated ServiceType for protobuf service grader_service.GraderPrivate
 */
export const GraderPrivate = new ServiceType('grader_service.GraderPrivate', [
  { name: 'RemoveUserInformation', options: {}, I: UserId, O: Empty },
])
/**
 * @generated ServiceType for protobuf service grader_service.GraderPublic
 */
export const GraderPublic = new ServiceType('grader_service.GraderPublic', [
  { name: 'SubmitTestAnswers', options: {}, I: SubmitTestAnswersEntry, O: Empty },
  { name: 'GetAllTestResults', options: {}, I: SubmissionId, O: AllTestResults },
  { name: 'GetTestResult', options: {}, I: GetTestResultEntry, O: TestResult },
  { name: 'OverrideAnswerResult', options: {}, I: OverrideAnswerResultEntry, O: Empty },
  { name: 'RegradeTest', options: {}, I: SubmissionId, O: Empty },
  { name: 'GetTestDetails', options: {}, I: SubmissionId, O: TestDetails },
  { name: 'SubmitGradableFile', options: {}, I: SubmitGradableFileEntry, O: Empty },
  { name: 'GetAllFileGrades', options: {}, I: GetAllFileGradesEntry, O: FileGrades },
  { name: 'GradeFile', options: {}, I: GradeFileEntry, O: Empty },
  { name: 'GetFileGrade', options: {}, I: GetFileGradeEntry, O: FileGrade },
  { name: 'GetContestResult', options: {}, I: GetContestResultEntry, O: ContestResult },
  { name: 'GetTaskResult', options: {}, I: TaskEntry, O: TaskResult },
  { name: 'GetAssessmentResult', options: {}, I: GetAssessmentResultEntry, O: AssessmentResult },
  { name: 'GetSectionStatistics', options: {}, I: SectionStatisticsRequest, O: SectionStatistics },
  { name: 'GetCourseStatistics', options: {}, I: CourseStatisticsRequest, O: CourseStatistics },
  { name: 'GetPersonalCourseGrades', options: {}, I: CourseStatisticsRequest, O: PersonalCourseGrades },
  { name: 'GetCourseStudentsGrades', options: {}, I: CourseId, O: CourseStudentsGrades },
])
