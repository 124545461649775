import { CoursesClient } from '@/protogen/courses_service/courses_service.client'
import {
  CourseId,
  CourseInfo,
  DeclinedEmails,
  ExtendedCourseInfo,
  GetAllCoursesInfoResponse,
  GetAllCoursesPermissionsResponse,
  GetSectionInfosResponse,
  SectionId,
  SectionInfo,
  UserIds,
  Permissions,
  GetSectionInfoResponse,
  IsPublicResponse,
  CreateBlockRequest,
  UpdateBlockRequest,
  BlockId,
  ChangeBlockPositionRequest,
  OrganizationId,
  BlockInfos,
  BlockCourses,
  CourseBlockEntry,
} from '@/protogen/courses_service/courses_service'
import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport'
import { CourseForm } from '@/interfaces/forms'
import { metaOptions } from './auth'
import { apiRequestWrapper } from '@/helpers/apiWrapper'

const client = new CoursesClient(
  new GrpcWebFetchTransport({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
  }),
)

export default () => ({
  async createBlock(
    organizationId: number,
    name: string,
    description: string,
    startTime: bigint,
    endTime: bigint,
  ): Promise<void> {
    const request = CreateBlockRequest.create({
      organizationId,
      name,
      description,
      startTime,
      endTime,
    })
    return apiRequestWrapper(async () => {
      await client.createBlock(request, metaOptions)
    })
  },
  async updateBlock(
    blockId: number,
    name: string,
    description: string,
    startTime: bigint,
    endTime: bigint,
  ): Promise<void> {
    const request = UpdateBlockRequest.create({
      blockId,
      name,
      description,
      startTime,
      endTime,
    })
    return apiRequestWrapper(async () => {
      await client.updateBlock(request, metaOptions)
    })
  },
  async deleteBlock(id: number): Promise<void> {
    const request = BlockId.create({
      id,
    })
    return apiRequestWrapper(async () => {
      await client.deleteBlock(request, metaOptions)
    })
  },
  async changeBlockPosition(blockId: number, newIndex: number): Promise<void> {
    const request = ChangeBlockPositionRequest.create({
      blockId,
      newIndex,
    })
    return apiRequestWrapper(async () => {
      await client.changeBlockPosition(request, metaOptions)
    })
  },
  async getOrganizationBlocks(organizationId: number): Promise<BlockInfos> {
    const request = OrganizationId.create({
      organizationId,
    })
    return apiRequestWrapper(async () => {
      const response = await client.getOrganizationBlocks(request, metaOptions)
      return response.response
    })
  },
  async getOrganizationBlockCourses(organizationId: number): Promise<BlockCourses> {
    const request = OrganizationId.create({
      organizationId,
    })
    return apiRequestWrapper(async () => {
      const response = await client.getOrganizationBlockCourses(request, metaOptions)
      return response.response
    })
  },
  async addCourseToBlock(courseId: number, blockId: number, specialityId: number): Promise<void> {
    const request = CourseBlockEntry.create({
      courseId,
      blockId,
      specialityId,
    })
    return apiRequestWrapper(async () => {
      await client.addCourseToBlock(request, metaOptions)
    })
  },
  async removeCourseFromBlock(courseId: number, blockId: number, specialityId: number): Promise<void> {
    const request = CourseBlockEntry.create({
      courseId,
      blockId,
      specialityId,
    })
    return apiRequestWrapper(async () => {
      await client.removeCourseFromBlock(request, metaOptions)
    })
  },
  async createCourse(course: CourseForm, organizationId: number): Promise<CourseId> {
    const request = CourseInfo.fromJson({
      organizationId,
      name: course.name,
      description: course.description,
      isPublic: course.isPublic,
    })
    return apiRequestWrapper(async () => {
      const response = await client.createCourse(request, metaOptions)
      return response.response
    })
  },
  async updateCourseInfo(
    organizationId: number,
    name: string,
    description: string,
    isPublic: boolean,
    courseId: number,
  ): Promise<void> {
    const request = {
      courseId,
      courseInfo: { organizationId, name, description, isPublic },
    }
    return apiRequestWrapper(async () => {
      await client.updateCourseInfo(request, metaOptions)
    })
  },
  async getCourseInfo(courseId: number): Promise<ExtendedCourseInfo> {
    const request = { courseId }
    return apiRequestWrapper(async () => {
      const response = await client.getCourseInfo(request, metaOptions)
      return response.response
    })
  },
  async getAllCoursesInfo(organizationId: number): Promise<GetAllCoursesInfoResponse> {
    const request = { organizationId }
    return apiRequestWrapper(async () => {
      const response = await client.getAllCoursesInfo(request, metaOptions)
      return response.response
    })
  },
  async getDeletedCoursesInfo(organizationId: number): Promise<GetAllCoursesInfoResponse> {
    const request = { organizationId }
    return apiRequestWrapper(async () => {
      const response = await client.getDeletedCoursesInfo(request, metaOptions)
      return response.response
    })
  },
  async deleteCourse(courseId: number): Promise<void> {
    const request = { courseId }
    return apiRequestWrapper(async () => {
      await client.deleteCourse(request, metaOptions)
    })
  },
  async restoreCourse(courseId: number): Promise<void> {
    const request = { courseId }
    return apiRequestWrapper(async () => {
      await client.restoreCourse(request, metaOptions)
    })
  },
  async isPublic(courseId: number): Promise<IsPublicResponse> {
    const request = { courseId }
    return apiRequestWrapper(async () => {
      const response = await client.isPublic(request, metaOptions)
      return response.response
    })
  },
  async getCoursePermissions(courseId: number): Promise<Permissions> {
    const request = { courseId }
    return apiRequestWrapper(async () => {
      const response = await client.getCoursePermissions(request, metaOptions)
      return response.response
    })
  },
  async getAllCoursesPermissions(organizationId: number): Promise<GetAllCoursesPermissionsResponse> {
    const request = { organizationId }
    return apiRequestWrapper(async () => {
      const response = await client.getAllCoursesPermissions(request, metaOptions)
      return response.response
    })
  },
  async addTeacher(userId: number, courseId: number): Promise<void> {
    const request = { courseId, userId }
    return apiRequestWrapper(async () => {
      await client.addTeacher(request, metaOptions)
    })
  },
  async addStudent(userId: number, courseId: number): Promise<void> {
    const request = { courseId, userId }
    return apiRequestWrapper(async () => {
      await client.addStudent(request, metaOptions)
    })
  },
  async addStudents(emails: string[], courseId: number): Promise<DeclinedEmails> {
    const request = { courseId, userEmails: { emails } }
    return apiRequestWrapper(async () => {
      const response = await client.addStudents(request, metaOptions)
      return response.response
    })
  },
  async removeTeacher(userId: number, courseId: number): Promise<void> {
    const request = { userId, courseId }
    return apiRequestWrapper(async () => {
      await client.removeTeacher(request, metaOptions)
    })
  },
  async removeStudent(userId: number, courseId: number): Promise<void> {
    const request = { userId, courseId }
    return apiRequestWrapper(async () => {
      await client.removeStudent(request, metaOptions)
    })
  },
  async getTeachers(courseId: number): Promise<UserIds> {
    const request = { courseId }
    return apiRequestWrapper(async () => {
      const response = await client.getTeachers(request, metaOptions)
      return response.response
    })
  },
  async getStudents(courseId: number): Promise<UserIds> {
    const request = { courseId }
    return apiRequestWrapper(async () => {
      const response = await client.getStudents(request, metaOptions)
      return response.response
    })
  },
  async createSection(courseId: number, name: string, description: string): Promise<SectionId> {
    const request = { courseId, name, description }
    return apiRequestWrapper(async () => {
      const response = await client.createSection(request, metaOptions)
      return response.response
    })
  },
  async getAllSectionsInfo(courseId: number): Promise<GetSectionInfosResponse> {
    const request = { courseId }
    return apiRequestWrapper(async () => {
      const response = await client.getAllSectionsInfo(request, metaOptions)
      return response.response
    })
  },
  async getDeletedSectionsInfo(courseId: number): Promise<GetSectionInfosResponse> {
    const request = { courseId }
    return apiRequestWrapper(async () => {
      const response = await client.getDeletedSectionsInfo(request, metaOptions)
      return response.response
    })
  },
  async getSectionInfo(sectionId: number): Promise<GetSectionInfoResponse> {
    const request = { sectionId }
    return apiRequestWrapper(async () => {
      const response = await client.getSectionInfo(request, metaOptions)
      return response.response
    })
  },
  async updateSectionInfo(sectionId: number, sectionInfo: SectionInfo): Promise<void> {
    const request = { sectionId, sectionInfo }
    return apiRequestWrapper(async () => {
      await client.updateSectionInfo(request, metaOptions)
    })
  },
  async updateSectionsOrder(courseId: number, sectionIds: number[]): Promise<void> {
    const request = { courseId, sectionIds }
    return apiRequestWrapper(async () => {
      await client.updateSectionsOrder(request, metaOptions)
    })
  },
  async deleteSection(sectionId: number): Promise<void> {
    const request = { sectionId }
    return apiRequestWrapper(async () => {
      await client.deleteSection(request, metaOptions)
    })
  },
  async restoreSection(sectionId: number): Promise<void> {
    const request = { sectionId }
    return apiRequestWrapper(async () => {
      await client.restoreSection(request, metaOptions)
    })
  },
})
