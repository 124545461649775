import { BlockInfos } from '@/protogen/courses_service/courses_service'
import { BlockCourse, OrganizationBlock } from '@/types/organization/organization'

import { convertFromTimeZone } from './time/convertDate'

export const formatBlockInfos = (blockList: BlockInfos): OrganizationBlock[] => {
  const { blockIds, names, descriptions, startTimes, endTimes } = blockList

  const courses: BlockCourse[] = []

  return blockIds.map((blockId, index) => ({
    blockId,
    name: names[index],
    description: descriptions[index],
    startTime: convertFromTimeZone(startTimes[index]),
    endTime: convertFromTimeZone(endTimes[index]),
    courses,
  }))
}
